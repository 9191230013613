import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  TableCell,
  TableRow,
  TableBody,
  Table,
  FormControl,
  Container,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormLabel,
  CircularProgress,
  Backdrop,
} from "@mui/material";

import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";
import TotalScore from "../../navigation/TotalScore";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";

const MAX_SCORE = 16;

export default function OHAT() {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const [formData, setFormData] = React.useState({
    title: "OHAT_J",
    name: "",
    days: "",
    dentures: "",
    dentures2: "",
    selectValue: "",
    selectValue2: "",
    selectValue3: "",
    selectValue4: "",
    selectValue5: "",
    selectValue6: "",
    selectValue7: "",
    selectValue8: "",
  });

  const initialFormData = {
    title: "OHAT-J",
    name: "",
    days: "",
    dentures: "",
    dentures2: "",
    selectValue: "",
    selectValue2: "",
    selectValue3: "",
    selectValue4: "",
    selectValue5: "",
    selectValue6: "",
    selectValue7: "",
    selectValue8: "",
  };
  const calculateTotalScore = () => {
    return (
      +formData.selectValue +
      +formData.selectValue2 +
      +formData.selectValue3 +
      +formData.selectValue4 +
      +formData.selectValue5 +
      +formData.selectValue6 +
      +formData.selectValue7 +
      +formData.selectValue8
    );
  };

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,
        totalScore: calculateTotalScore().toString(),
        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/OHAT_J`,
        dataToSend,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "OHAT_J";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]); // 依存配列にsessionIdを指定

  const handleRefresh = () => {
    setFormData(initialFormData);
  };

  function CustomRadioWithHelper({ label, helperText, checked, ...props }) {
    return (
      <div>
        <FormControlLabel
          control={<Radio checked={checked} {...props} />}
          label={label}
        />
        {helperText && (
          <FormHelperText style={{ marginLeft: "12px", color: "red" }}>
            {helperText}
          </FormHelperText>
        )}
      </div>
    );
  }

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          口腔（OHAT-J）
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <Box sx={{ paddingLeft: "5px", paddingBottom: "2px" }}></Box>
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "one",
                  label: "口唇",
                },
                {
                  id: "two",
                  label: "舌",
                },
                {
                  id: "three",
                  label: "歯肉・粘膜",
                },
                {
                  id: "four",
                  label: "唾液",
                },
                {
                  id: "five",
                  label: "残存歯",
                },

                {
                  id: "six",
                  label: "義歯",
                },
                {
                  id: "seven",
                  label: "口腔清掃",
                },
                {
                  id: "eight",
                  label: "歯痛",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "25%",
                      color: "white",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "one" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <RadioGroup
                                  name="selectValue"
                                  value={formData.selectValue}
                                  onChange={handleChange}
                                >
                                  <CustomRadioWithHelper
                                    value="0"
                                    label="0   健全"
                                    helperText="正常、湿潤、ピンク"
                                  />
                                  <CustomRadioWithHelper
                                    value="1"
                                    label="1   やや不良"
                                    helperText="乾燥、ひび割れ、口角の発赤"
                                  />
                                  <CustomRadioWithHelper
                                    value="2"
                                    label="2    病的"
                                    helperText="腫脹や腫瘤、赤色斑、白色斑、潰瘍性出血、口角からの出血、潰瘍"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "two" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <RadioGroup
                                  name="selectValue2"
                                  value={formData.selectValue2}
                                  onChange={handleChange}
                                >
                                  <CustomRadioWithHelper
                                    value="0"
                                    label="0   健全"
                                    helperText="正常、湿潤、ピンク"
                                  />
                                  <CustomRadioWithHelper
                                    value="1"
                                    label="1   やや不良"
                                    helperText="不整、亀裂、発赤、舌苔付着"
                                  />
                                  <CustomRadioWithHelper
                                    value="2"
                                    label="2    病的"
                                    helperText="赤色斑、白色斑、潰瘍、腫脹"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "three" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <RadioGroup
                                  name="selectValue3"
                                  value={formData.selectValue3}
                                  onChange={handleChange}
                                >
                                  <CustomRadioWithHelper
                                    value="0"
                                    label="0   健全"
                                    helperText="正常、湿潤、ピンク"
                                  />
                                  <CustomRadioWithHelper
                                    value="1"
                                    label="1   やや不良"
                                    helperText="乾燥、光沢、粗造、発赤、部分的な(1-6歯分)腫脹、義歯下の一部潰瘍"
                                  />
                                  <CustomRadioWithHelper
                                    value="2"
                                    label="2    病的"
                                    helperText="歯の動揺、出血(7歯分以上)、潰瘍、白色斑、潰瘍、発赤、圧痛"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "four" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <RadioGroup
                                  name="selectValue4"
                                  value={formData.selectValue4}
                                  onChange={handleChange}
                                >
                                  <CustomRadioWithHelper
                                    value="0"
                                    label="0   健全"
                                    helperText="湿潤、漿液性"
                                  />
                                  <CustomRadioWithHelper
                                    value="1"
                                    label="1   やや不良"
                                    helperText="乾燥、べたつく粘膜、少量の唾液、口渇感若干あり"
                                  />
                                  <CustomRadioWithHelper
                                    value="2"
                                    label="2    病的"
                                    helperText="赤く干からびた状態、唾液ほぼなし、粘性の高い唾液、口渇感あり"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "five" && (
                          <>
                            <Box sx={{ marginLeft: 3, padding: 1 }}>
                              <FormControl>
                                <FormLabel id="existence">残存歯</FormLabel>
                                <RadioGroup
                                  name="dentures"
                                  value={formData.dentures}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <FormControlLabel
                                    value="有"
                                    control={<Radio />}
                                    label="有"
                                  />
                                  <FormControlLabel
                                    value="無"
                                    control={<Radio />}
                                    label="無"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <RadioGroup
                                  name="selectValue5"
                                  value={formData.selectValue5}
                                  onChange={handleChange}
                                >
                                  <CustomRadioWithHelper
                                    value="0"
                                    label="0   健全"
                                    helperText="歯・歯根のう蝕または破折なし"
                                  />
                                  <CustomRadioWithHelper
                                    value="1"
                                    label="1   やや不良"
                                    helperText="3本以下のう蝕、歯の破折、残根、咬耗"
                                  />
                                  <CustomRadioWithHelper
                                    value="2"
                                    label="2    病的"
                                    helperText="4本以上のう蝕、歯の破折、残根、非常に強い咬耗、義歯接着剤が必要"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "six" && (
                          <>
                            <Box sx={{ marginLeft: 3, padding: 1 }}>
                              <FormControl>
                                <FormLabel id="existence">義歯</FormLabel>
                                <RadioGroup
                                  value={formData.dentures2}
                                  onChange={handleChange}
                                  name="dentures2"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <FormControlLabel
                                    value="有"
                                    control={<Radio />}
                                    label="有"
                                  />
                                  <FormControlLabel
                                    value="無"
                                    control={<Radio />}
                                    label="無"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <RadioGroup
                                  name="selectValue6"
                                  value={formData.selectValue6}
                                  onChange={handleChange}
                                >
                                  <CustomRadioWithHelper
                                    value="0"
                                    label="0   健全"
                                    helperText="正常、義歯、人工歯の破折なし、普通に装着できる状態"
                                  />
                                  <CustomRadioWithHelper
                                    value="1"
                                    label="1   やや不良"
                                    helperText="一部位の義歯、人工歯の破折、毎日1-2時間の装着のみ可能"
                                  />
                                  <CustomRadioWithHelper
                                    value="2"
                                    label="2    病的"
                                    helperText="⼆部位以上の義⻭、⼈⼯⻭の破折
                                 義⻭紛失、義⻭不適のため未装着
                                 義⻭接着剤が必要"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "seven" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <RadioGroup
                                  name="selectValue7"
                                  value={formData.selectValue7}
                                  onChange={handleChange}
                                >
                                  <CustomRadioWithHelper
                                    value="0"
                                    label="0   健全"
                                    helperText="⼝腔清掃状態良好、⾷渣、⻭⽯、プラークなし "
                                  />
                                  <CustomRadioWithHelper
                                    value="1"
                                    label="1   やや不良"
                                    helperText="1-2部位に⾷渣、⻭⽯、プラークあり、若⼲⼝臭あり"
                                  />
                                  <CustomRadioWithHelper
                                    value="2"
                                    label="2    病的"
                                    helperText="多くの部位に⾷渣、⻭⽯、プラークあり、強い⼝臭あり"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "eight" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <RadioGroup
                                  name="selectValue8"
                                  value={formData.selectValue8}
                                  onChange={handleChange}
                                >
                                  <CustomRadioWithHelper
                                    value="0"
                                    label="0   健全"
                                    helperText="疼痛を⽰す⾔動的、⾝体的な兆候なし"
                                  />
                                  <CustomRadioWithHelper
                                    value="1"
                                    label="1   やや不良"
                                    helperText="疼痛を⽰す⾔動的な兆候あり： 顔を引きつらせる、⼝唇を噛む ⾷事しない、攻撃的になる"
                                  />
                                  <CustomRadioWithHelper
                                    value="2"
                                    label="2    病的"
                                    helperText="疼痛を⽰す⾝体的な兆候あり： 頬、⻭⾁の腫脹、⻭の破折、潰瘍 ⻭⾁下膿瘍。⾔動的な徴候もあり"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TotalScore formData={formData} maxScore={MAX_SCORE} />
          <Button
            variant="contained"
            color="success"
            sx={{
              position: "fixed",
              right: [10, 50, 100],
              top: [150, 150, 100],
              size: ["small", "medium", "large"],
              zIndex: 12,
            }}
            onClick={handleSaveDraft}
          >
            一時保存
          </Button>
          <ImageModal
            imageUrl={`${process.env.PUBLIC_URL}/OHAT.png`} // テンプレートリテラルを使用
            imageTitle="OHAT-J"
            buttonText="PDFを確認"
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
        {pdfFilePath && (
          <PdfModal
            open={isPdfModalOpen}
            onClose={() => setIsPdfModalOpen(false)}
            pdfFilePath={pdfFilePath}
          />
        )}
        <FixedBottomNavigation
          editPDF={editPDF}
          handleRefresh={handleRefresh}
        />
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
