import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const ContactForm = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [formData, setFormData] = useState({
    name: "",
    kana: "",
    email: "",
    purpose: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false); // 送信成功状態

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData }),
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => alert(error));
  };

  return (
    <Box
      component="form"
      name="contact"
      method="POST"
      data-netlify="true"
      action="/thanks/"
      noValidate
      autoComplete="off"
      sx={{ maxWidth: isSmallScreen ? "80%" : "500px", margin: "auto" ,mt: isSmallScreen ? 5 :10,pb: isSmallScreen ? 5 :10}}
      onSubmit={handleSubmit}
    >
      {submitted && (
        <Alert severity="success" sx={{ mb: 2 }}>
          お問い合わせありがとうございました。すぐに返信いたします。
        </Alert>
      )}
      {/* Netlify用の隠しフィールド */}
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>

      <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom>
        お問い合わせフォーム
      </Typography>

      <TextField
        label="氏名"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        size={isSmallScreen ? "small" : "medium"}
      />
      <TextField
        label="メールアドレス"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
        size={isSmallScreen ? "small" : "medium"}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="purpose-label">お問い合わせの目的</InputLabel>
        <Select
          labelId="purpose-label"
          id="purpose"
          name="purpose"
          value={formData.purpose}
          label="お問い合わせの目的"
          onChange={handleChange}
          size="medium"
        >
          <MenuItem value="app">アプリについて</MenuItem>
          <MenuItem value="donation-kihu">寄付について</MenuItem>
          <MenuItem value="pdf-request">評価用紙の作成の依頼</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="件名"
        name="kenmei"
        value={formData.kenmei}
        onChange={handleChange}
        fullWidth
        margin="normal"
        size={isSmallScreen ? "small" : "medium"}
      />

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        お問い合わせ内容
      </Typography>
      <TextareaAutosize
        minRows={4}
        name="message"
        value={formData.message}
        onChange={handleChange}
        style={{ width: isSmallScreen ? "92%" : "480px", padding: "10px", resize: "none" }}
      />
      <Typography variant="body2" color="red">
        お問い合わせ内容を詳細に記載してください。
      </Typography>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 5, mb: 3,width: "100%", }}
      >
        送信
      </Button>
      <Typography variant="body2" color="red" >
        ※
        「件名：評価用紙キットのお問い合わせについて」という内容で24時間以内に返信させていただきます。
        <br />※メールアドレスの記載が誤っている場合、ご連絡することができません。再度の確認をお願いいたします。
      </Typography>
    </Box>
  );
};

export default ContactForm;
