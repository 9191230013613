import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";
import NumberTable from "../../common/pdfTable/NumberTable";
import axios from "axios";
import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";

const ROMLeg = () => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const createInitialFormData = () => {
    const initialFormData = {
      title: "関節可動域(ROM)下肢・体幹",
      name: "",
      days: "",
      therapy: "",
    };

    for (let i = 1; i <= 24; i++) {
      initialFormData[`selectValue${i}Right`] = ""; // 右側の値
      initialFormData[`selectValue${i}Left`] = ""; // 左側の値
    }

    return initialFormData;
  };

  const [formData, setFormData] = useState(createInitialFormData());

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,
        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/ROMLeg`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "関節可動域(ROM)下肢・体幹";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]); // 依存配列にsessionIdを指定

  const handleRefresh = () => {
    setFormData(createInitialFormData());
  };

  const tableRows = [
    {
      label: "屈曲(125)",
      name: "selectValue1",
      type: "number",
      instruction: "(　)は参考可動域です。",
    },
    {
      label: "屈曲(SLR)(90)",
      name: "selectValue2",
      type: "number",
      instruction: "「参考資料」では可動域の測定方法を確認可能です。",
    },
    {
      label: "伸展(15)",
      name: "selectValue3",
      type: "number",
    },
    {
      label: "外転(45)",
      name: "selectValue4",
      type: "number",
    },
    {
      label: "内転(20)",
      name: "selectValue5",
      type: "number",
    },
    {
      label: "外旋(45)",
      name: "selectValue6",
      type: "number",
    },
    {
      label: "内旋(45)",
      name: "selectValue7",
      type: "number",
    },
  ];

  const tableRows2 = [
    {
      label: "屈曲(130)",
      name: "selectValue8",
      type: "number",
    },
    {
      label: "伸展(0)",
      name: "selectValue9",
      type: "number",
    },
  ];

  const tableRows3 = [
    {
      label: "底屈(45)",
      name: "selectValue10",
      type: "number",
    },
    {
      label: "背屈(膝屈曲)(25)",
      name: "selectValue11",
      type: "number",
    },
    {
      label: "背屈(膝伸展)(15)",
      name: "selectValue12",
      type: "number",
    },
  ];
  const tableRows4 = [
    {
      label: "外がえし(20)",
      name: "selectValue13",
      type: "number",
    },
    {
      label: "内がえし(30)",
      name: "selectValue14",
      type: "number",
    },
    {
      label: "外転(10)",
      name: "selectValue15",
      type: "number",
    },
    {
      label: "内転(20)",
      name: "selectValue16",
      type: "number",
    },
  ];
  const tableRows5 = [
    {
      label: "屈曲(60)",
      name: "selectValue17",
      type: "number",
    },
    {
      label: "伸展(50)",
      name: "selectValue18",
      type: "number",
    },
    {
      label: "回旋(60)",
      name: "selectValue19",
      type: "number",
    },
    {
      label: "側屈(50)",
      name: "selectValue20",
      type: "number",
    },
  ];
  const tableRows6 = [
    {
      label: "屈曲(45)",
      name: "selectValue21",
      type: "number",
    },
    {
      label: "伸展(30)",
      name: "selectValue22",
      type: "number",
    },
    {
      label: "回旋(40)",
      name: "selectValue23",
      type: "number",
    },
    {
      label: "側屈(50)",
      name: "selectValue24",
      type: "number",
    },
  ];

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          関節可動域(ROM)下肢・体幹
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
          下肢
        </Typography>
        <Typography variant="h6" gutterBottom>
          股
        </Typography>
        <NumberTable
          rows={tableRows}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          膝
        </Typography>
        <NumberTable
          rows={tableRows2}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          足
        </Typography>
        <NumberTable
          rows={tableRows3}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          足部
        </Typography>
        <NumberTable
          rows={tableRows4}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
          体幹
        </Typography>
        <Typography variant="h6" gutterBottom>
          頸部
        </Typography>
        <NumberTable
          rows={tableRows5}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          胸腰部
        </Typography>
        <NumberTable
          rows={tableRows6}
          formData={formData}
          handleChange={handleChange}
        />
      </Box>

      <Button
        variant="contained"
        color="success"
        sx={{
          position: "fixed",
          right: [10, 50, 100],
          top: [150, 150, 100],
          size: ["small", "medium", "large"],
          zIndex: 12,
        }}
        onClick={handleSaveDraft}
      >
        一時保存
      </Button>

      <ImageModal
        imageUrl={`${process.env.PUBLIC_URL}/ROM-leg.png`} // テンプレートリテラルを使用
        imageTitle="関節可動域検査(ROM)下肢・体幹"
        buttonText="参考資料"
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      {pdfFilePath && (
        <PdfModal
          open={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfFilePath={pdfFilePath}
        />
      )}
      <FixedBottomNavigation editPDF={editPDF} handleRefresh={handleRefresh} />

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ROMLeg;
