import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, Box,CardActionArea } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PersonIcon from '@mui/icons-material/Person';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InfoIcon from '@mui/icons-material/Info';

const getStatusIcon = (status) => {
  switch (status) {
    case '確定':
      return <PictureAsPdfIcon color="action" />;
    case '一時保存':
      return <SaveAltIcon color="action" />;
    default:
      return <InfoIcon color="action" />;
  }
};


const SessionCard = ({ item, onEdit, onView, onDelete }) => {
  const handleCardClick = () => {
    if (item.status === "一時保存") {
      onEdit(item);
    } else {
      onView(item.gcsUrl);
    }
  };

  function convertToJapaneseEra(date) {
    const eraMap = [
      { start: new Date('2019-05-01'), era: '令和', yearOffset: 2018 },
      // ... さらに過去の元号も追加できます
    ];
  
    for (const { start, era, yearOffset } of eraMap) {
      if (date >= start) {
        const year = date.getFullYear() - yearOffset;
        return `${era}${year}年${date.getMonth() + 1}月${date.getDate()}日${date.getHours()}時${date.getMinutes()}分`;
      }
    }
    return date.toLocaleDateString();
  }
  
  

  // ステータスに応じて背景色を設定
  const cardBackgroundColor = item.status === "一時保存" ? '#f5f5f5' : '#ffffff'; // 一時保存は黄色、確定は青色

  return (
    <Card variant="outlined" sx={{ mb: 1, backgroundColor: cardBackgroundColor, boxShadow: 3, borderRadius: 2 }}>
    <CardActionArea onClick={handleCardClick}>
      <CardContent>
        <Typography variant="body1" gutterBottom>{item.title}</Typography>
        <Box display="flex" alignItems="center" mb={1} mt={2}>
          <PersonIcon color="action" sx={{ mr: 1 }} />
          <Typography variant="body2" color="textSecondary">患者名：{item.inputData?.name || "未設定"}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <EventNoteIcon color="action" sx={{ mr: 1 }} />
          <Typography variant="body2" color="textSecondary">日時：{item.createdAt
    ? convertToJapaneseEra(new Date(item.createdAt))
    : "-"}</Typography>
        </Box>
        <Box display="flex" alignItems="center" >
          {getStatusIcon(item.status)}
          <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }}>状態：{item.status}</Typography>
        </Box>
      </CardContent>
    </CardActionArea>
    <CardActions>
      <Button sx={{ml:1}}startIcon={<DeleteIcon />} variant="outlined" color="error" onClick={() => onDelete(item)}>
        削除
      </Button>
    </CardActions>
  </Card>
  );
};

export default SessionCard;
