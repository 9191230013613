import React, { useRef, useEffect } from "react";
import {
  TableCell,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";
import useDynamicSelectWidth from "./useDynamicSelectWidth";

const SelectTableRow = ({
  label,
  name,
  value,
  options,
  instruction,
  handleChange,
}) => {
  const [selectWidth, updateSelectWidth] = useDynamicSelectWidth();
  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef.current) {
      updateSelectWidth(selectRef.current);
    }
  }, [value, updateSelectWidth]);

  return (
    <TableRow>
  <TableCell
    sx={{ backgroundColor: "#5972ff", width: "38%", color: "white" }}
  >
    {label}
  </TableCell>
  <TableCell align="left">
    <FormControl variant="outlined" size="md" sx={{ width: selectWidth }}>
      <InputLabel>結果</InputLabel>
      <Select
        labelId={`select-value-label-${name}`}
        name={name}
        value={value}
        onChange={(e) => {
          handleChange(e);
          updateSelectWidth(selectRef.current);
        }}
        sx={{ minWidth: 230 }}
        label="点数"
        SelectDisplayProps={{
          style: {
            whiteSpace: "normal",
            overflowWrap: "break-word",
            maxWidth: "400px",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    {instruction && (
      <Grid sx={{ marginTop: "8px" }}>
        <Typography
          sx={{
            color: "red",
            fontWeight: "bold",
            fontSize: "12px",
          }}
        >
          {instruction.split("\n").map((str, index, array) => (
            <span key={index}>
              {str}
              {index !== array.length - 1 && <br />}
            </span>
          ))}
        </Typography>
      </Grid>
    )}
  </TableCell>
</TableRow>

  );
};

export default SelectTableRow;
