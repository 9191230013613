import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DialogContent,DialogTitle,DialogContentText } from '@mui/material';

function ConfirmModal({ open, onClose, onConfirm }) {
    return (
      <Dialog
    open={open}
    onClose={onClose}
    fullWidth={true}
    maxWidth="md"
>
    <DialogTitle>確認</DialogTitle>
    <DialogContent>
        <DialogContentText >
            確定をクリックで保存されダウンロード可能です。
        </DialogContentText>
        <DialogContentText color="error">
            編集中はキャンセルをクリックしてください。
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
            キャンセル
        </Button>
        <Button onClick={onConfirm} color="primary" variant="outlined">
            確定
        </Button>
    </DialogActions>
</Dialog>

    );
}

export default ConfirmModal;
