import { useState} from 'react';

function useDynamicSelectWidth(defaultWidth = 'auto') {
  const [selectWidth, setSelectWidth] = useState(defaultWidth);

  const calculateTextWidth = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "16px Arial"; // フォントスタイルを調整
    return context.measureText(text).width;
  };

  const updateSelectWidth = (selectElement) => {
    if (selectElement && selectElement.options && selectElement.selectedIndex !== -1) {
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      if (selectedOption) {
        const text = selectedOption.text;
        const width = calculateTextWidth(text);
        setSelectWidth(`${width + 20}px`); // 余白を加える
      }
    }
  };

  return [selectWidth, updateSelectWidth];
}

export default useDynamicSelectWidth;
