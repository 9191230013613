import React from "react";
import { Button, Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import CreateIcon from '@mui/icons-material/Create';

function Donation() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
   <Box>
  {isSmallScreen && (
    <>
       <Box sx={{ mb: 4,ml: 2, mr: 2 }}>
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
    <VolunteerActivismIcon sx={{ mr: 2, color: "#1976d2" }} />
    <Typography variant="body1" sx={{  fontWeight: "bold" }}>
      寄付について
    </Typography>
  </Box>
  <Typography variant="body2"sx={{  mb: 1,ml:2 }}>
    評価用紙キットは無料で利用可能です。少しでも役に立っていると感じたら、寄付をお願いします。
  </Typography>
  <Typography variant="body2" color="red" sx={{ml:2}}>
    ※ユーザー増加によっては、有料オプションも検討しています。
  </Typography>
</Box>

<Box sx={{ mb: 4 ,ml: 2, mr: 2}}>
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
    <CreateIcon sx={{ mr: 2, color: "#1976d2" }} />
    <Typography variant="body1" sx={{  fontWeight: "bold" }}>
      評価用紙の提供依頼について
    </Typography>
  </Box>
  <Typography variant="body2"sx={{  mb: 1,ml:2 }}>
    医療現場で利用されている様々な評価用紙があります。現場で使用している評価用紙をご提供いただければ、新たに作成いたします。
  </Typography>
  <Typography variant="body2" sx={{ml:2,mb:8}}>
    医療全体の発展のために、ご協力をお願いいたします。
  </Typography>
</Box>

    </>
  )}

  <Box sx={{ position: "relative", width: "100%", height: "auto" }}>
    <img
      src={`${process.env.PUBLIC_URL}/kihu.png`}
      alt="Kihu"
      style={{ width: '100%', height: 'auto', display: isSmallScreen ? 'none' : 'block' }}
    />
      <Button
        component={Link}
        to="/ContactForm"
        sx={{
          position: "absolute", // 位置を絶対位置に設定
          top: isSmallScreen ? "85%" : "88%", // 小さい画面では上から60%、大きい画面では76%
          left: isSmallScreen ? "50%" : "50%", // 小さい画面では左から50%、大きい画面では25%
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "50%" : "55%",
          color: "#FFFFFF",
          backgroundColor: "#0cc0df",
          fontSize: isSmallScreen ? "12px " : "40px ",
          fontWeight: "bold",
          boxShadow: "0px 4px 6px #888888 ",
          "&:hover": {
            backgroundColor: "#85DFEF",
          },
        }}
      >
        寄付&依頼フォーム
      </Button>
    </Box>
</Box>
  </>
  );
}

export default Donation;
