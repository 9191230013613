import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  TableCell,
  TableRow,
  TableBody,
  Table,
  FormControl,
  Container,
  Button,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import useDynamicSelectWidth from "components/common/pdfTable/useDynamicSelectWidth";

import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";
import TotalScore from "../../navigation/TotalScore";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";

const MAX_SCORE = 100;

export default function BathelIndex() {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const [formData, setFormData] = React.useState({
    title: "BathelIndex(BI)",
    name: "",
    days: "",
    therapy: "",
    selectValue: "0",
    selectValue2: "0",
    selectValue3: "0",
    selectValue4: "0",
    selectValue5: "0",
    selectValue6: "0",
    selectValue7: "0",
    selectValue8: "0",
    selectValue9: "0",
    selectValue10: "0",
  });

  const initialFormData = {
    title: "BathelIndex(BI)",
    name: "",
    days: "",
    theme: "",

    selectValue: "0",
    selectValue2: "0",
    selectValue3: "0",
    selectValue4: "0",
    selectValue5: "0",
    selectValue6: "0",
    selectValue7: "0",
    selectValue8: "0",
    selectValue9: "0",
    selectValue10: "0",
  };
  const calculateTotalScore = () => {
    return (
      +formData.selectValue +
      +formData.selectValue2 +
      +formData.selectValue3 +
      +formData.selectValue4 +
      +formData.selectValue5 +
      +formData.selectValue6 +
      +formData.selectValue7 +
      +formData.selectValue8 +
      +formData.selectValue9 +
      +formData.selectValue10
    );
  };

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [width1, updateWidth1] = useDynamicSelectWidth();
  const [width2, updateWidth2] = useDynamicSelectWidth();
  const [width3, updateWidth3] = useDynamicSelectWidth();
  const [width4, updateWidth4] = useDynamicSelectWidth();
  const [width5, updateWidth5] = useDynamicSelectWidth();
  const [width6, updateWidth6] = useDynamicSelectWidth();
  const [width7, updateWidth7] = useDynamicSelectWidth();
  const [width8, updateWidth8] = useDynamicSelectWidth();
  const [width9, updateWidth9] = useDynamicSelectWidth();
  const [width10, updateWidth10] = useDynamicSelectWidth();

  // 各セレクトボックスのための ref を作成
  const selectRef1 = useRef(null);
  const selectRef2 = useRef(null);
  const selectRef3 = useRef(null);
  const selectRef4 = useRef(null);
  const selectRef5 = useRef(null);
  const selectRef6 = useRef(null);
  const selectRef7 = useRef(null);
  const selectRef8 = useRef(null);
  const selectRef9 = useRef(null);
  const selectRef10 = useRef(null);

  useEffect(() => {
    // 初期ロード時に各セレクトボックスの幅を設定
    if (selectRef1.current) {
      updateWidth1(selectRef1.current);
    }
    if (selectRef2.current) {
      updateWidth2(selectRef2.current);
    }
    if (selectRef3.current) {
      updateWidth3(selectRef3.current);
    }
    if (selectRef4.current) {
      updateWidth4(selectRef4.current);
    }
    if (selectRef5.current) {
      updateWidth5(selectRef5.current);
    }
    if (selectRef6.current) {
      updateWidth6(selectRef6.current);
    }
    if (selectRef7.current) {
      updateWidth7(selectRef7.current);
    }
    if (selectRef8.current) {
      updateWidth8(selectRef8.current);
    }
    if (selectRef9.current) {
      updateWidth9(selectRef9.current);
    }
    if (selectRef10.current) {
      updateWidth10(selectRef10.current);
    }
  }, [
    updateWidth1,
    updateWidth2,
    updateWidth3,
    updateWidth4,
    updateWidth5,
    updateWidth6,
    updateWidth7,
    updateWidth8,
    updateWidth9,
    updateWidth10,
  ]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    const newValue = name === "name" ? convertName(value) : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue, // 直接値を使用する
    }));

    if (name === "selectValue") updateWidth1(event.target);
    if (name === "selectValue2") updateWidth2(event.target);
    if (name === "selectValue3") updateWidth3(event.target);
    if (name === "selectValue4") updateWidth4(event.target);
    if (name === "selectValue5") updateWidth5(event.target);
    if (name === "selectValue6") updateWidth6(event.target);
    if (name === "selectValue7") updateWidth7(event.target);
    if (name === "selectValue8") updateWidth8(event.target);
    if (name === "selectValue9") updateWidth9(event.target);
    if (name === "selectValue10") updateWidth10(event.target);
  };
  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,
        totalScore: calculateTotalScore().toString(),
        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/BathelIndex`,
        dataToSend,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");

    const pageName = "BathelIndex(BI)";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId,
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]); // 依存配列にsessionIdを指定

  const handleRefresh = () => {
    setFormData(initialFormData);
  };

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Bathel Index(BI)
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <Box sx={{ paddingLeft: "5px", paddingBottom: "2px" }}></Box>
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "one",
                  label: "食事",
                },
                {
                  id: "two",
                  label: "車椅子とベッド間の移乗",
                },
                {
                  id: "three",
                  label: "整容",
                },
                {
                  id: "four",
                  label: "トイレ動作",
                },
                {
                  id: "five",
                  label: "入浴",
                },

                {
                  id: "six",
                  label: "歩行",
                },
                {
                  id: "seven",
                  label: "階段昇降",
                },
                {
                  id: "eight",
                  label: "更衣",
                },
                {
                  id: "nine",
                  label: "排便コントロール",
                },
                {
                  id: "ten",
                  label: "排尿コントロール",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "38%",
                      color: "white",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "one" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width1 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  ref={selectRef1}
                                  name="selectValue"
                                  value={formData.selectValue}
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="10">
                                    <em>
                                      10
                                      自立、自助具などの装着可、標準的時間内に食べ終わる
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>5 部分介助</em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 全介助</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "two" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width2 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue2"
                                  ref={selectRef2}
                                  value={formData.selectValue2}
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="15">
                                    <em>
                                      15
                                      自立、ブレーキ、フットレストの操作も含む
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="10">
                                    <em>10 軽度の部分介助または監視を要する</em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 座ることは可能であるが監視を要する
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 全介助または不可能</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "three" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width3 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  value={formData.selectValue3}
                                  ref={selectRef3}
                                  name="selectValue3"
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="5">
                                    <em>5 自立</em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 部分介助または不可能</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "four" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width4 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue4"
                                  ref={selectRef4}
                                  value={formData.selectValue4}
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="10">
                                    <em>10 自立</em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5
                                      部分介助、体を支える、衣服、後始末に介助を要する
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 全介助または不可能</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "five" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width5 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue5"
                                  value={formData.selectValue5}
                                  ref={selectRef5}
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="5">
                                    <em>5 自立</em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 部分介助または不可能</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "six" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width6 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue6"
                                  ref={selectRef6}
                                  value={formData.selectValue6}
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="15">
                                    <em>
                                      15
                                      45M以上の歩行、補装具の使用の有無は問わず
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="10">
                                    <em>
                                      10 45M以上の介助歩行、歩行器の使用を含む
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5
                                      歩行不能の場合、車椅子にて45M以上の操作可能
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 上記以外</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "seven" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width7 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue7"
                                  ref={selectRef7}
                                  value={formData.selectValue7}
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="10">
                                    <em>
                                      10 自立、手すりなどの使用の有無は問わない
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>5 介助または監視を要する</em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 不能</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "eight" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width8 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue8"
                                  ref={selectRef8}
                                  value={formData.selectValue8}
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="10">
                                    <em>
                                      10 自立、靴、ファスナー、装具の着脱を含む
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5
                                      部分介助、標準的な時間内、半分以上は自立で行える
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 上記以外</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "nine" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width9 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue9"
                                  ref={selectRef9}
                                  value={formData.selectValue9}
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="10">
                                    <em>
                                      10 失禁なし、浣腸、坐薬の取り扱いも可能
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5
                                      ときに失禁あり、浣腸、坐薬の取り扱いにに介助を要する者も含む
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 上記以外</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "ten" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="md"
                                sx={{ width: width10 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue10"
                                  ref={selectRef10}
                                  value={formData.selectValue10}
                                  onChange={handleChange}
                                  label="Value"
                                  SelectDisplayProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                      maxWidth: "400px",
                                    },
                                  }}
                                >
                                  <MenuItem value="10">
                                    <em>10 失禁なし、収尿器の取り扱いも可能</em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5
                                      ときに失禁あり、収尿器の取り扱いに介助を要する者も含む
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="0">
                                    <em>0 上記以外</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TotalScore formData={formData} maxScore={MAX_SCORE} />
          <Button
            variant="contained"
            color="success"
            sx={{
              position: "fixed",
              right: [10, 50, 100],
              top: [150, 150, 100],
              size: ["small", "medium", "large"],
              zIndex: 12,
            }}
            onClick={handleSaveDraft}
          >
            一時保存
          </Button>
          <ImageModal
            imageUrl={`${process.env.PUBLIC_URL}/BathelIndex.png`} // テンプレートリテラルを使用
            imageTitle="BathelIndex(BI)"
            buttonText="PDFを確認"
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
        {pdfFilePath && (
          <PdfModal
            open={isPdfModalOpen}
            onClose={() => setIsPdfModalOpen(false)}
            pdfFilePath={pdfFilePath}
          />
        )}
        <FixedBottomNavigation
          editPDF={editPDF}
          handleRefresh={handleRefresh}
        />
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
