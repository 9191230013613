// CustomTable.js
import React from "react";
import { Table, TableBody } from "@mui/material";
import SelectTableRow from "./SelectTableRow";

const CustomTable = ({ rows, formData, handleChange }) => (
  <Table aria-label="simple table">
    <TableBody>
      {rows.map((row, index) => (
        <SelectTableRow
          key={index}
          label={row.label}
          name={row.name}
          value={formData[row.name]}
          options={row.options}
          instruction={row.instruction}
          handleChange={handleChange}
        />
      ))}
    </TableBody>
  </Table>
);

export default CustomTable;


