import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";

const defaultTheme = createTheme();

function PasswordReset() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success"); // 'error' または 'info' にもできます

  const validatePassword = (password) => {
    return password.length >= 6 && /^[a-zA-Z0-9]+$/.test(password)
      ? ""
      : "パスワードは6文字以上の英数字である必要があります。";
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "password") {
      setPassword(value);
      setError(validatePassword(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setError(value !== password ? "パスワードが一致しません。" : "");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const passwordError = validatePassword(password);
    const confirmPasswordError =
      confirmPassword !== password ? "パスワードが一致しません。" : "";
    if (passwordError || confirmPasswordError) {
      setError(passwordError || confirmPasswordError);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/password-reset`, {
        token,
        password,
      });
      setAlertMessage(
        "パスワードのリセットができました。ログインしてください。"
      );
      setAlertSeverity("success");
      setTimeout(() => navigate("/login"), 1500); // リダイレクト
    } catch (error) {
      setAlertMessage(error.response.data);
      setAlertSeverity("error");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3} sx={{ mt: 3, padding: 3, borderRadius: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                bgcolor: "#0cc0df",
                width: 45,
                height: 45,
                boxShadow: 3,
              }}
            >
              <LockOutlinedIcon sx={{ fontSize: 32, color: "white" }} />
            </Avatar>
            <Typography component="h1" variant="h6">
              新しいパスワードの設定
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="新しいパスワード"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={handleChange}
                error={!!error && confirmPassword.length > 0} // パスワード確認フィールドでのエラー表示
                helperText={confirmPassword.length > 0 ? error : ""} // パスワード確認エラーメッセージ
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="パスワード確認"
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontFamily: "'Noto Sans JP', sans-serif !important",
                  backgroundColor: "#0cc0df",
                  fontWeight: "bold !important",
                  "&:hover": { backgroundColor: "#0097b2 !important" },
                }}
              >
                パスワードリセット
              </Button>
              {alertMessage && (
                <Alert severity={alertSeverity} sx={{ mt: 2 }}>
                  {alertMessage}
                </Alert>
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default PasswordReset;
