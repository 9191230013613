import React, { useState } from 'react';

function TestNamePage() {
  const [name, setName] = useState('');
  const [convertedName, setConvertedName] = useState('');

  function handleChange(event) {
    const newName = event.target.value;
    setName(newName);
    setConvertedName(convertName(newName));
  }

  function convertName(name) {
    return name.split('').map((char, index) => (index % 2 === 0 ? '○' : char)).join('');
  }

  return (
    <div>
      <input type="text" value={name} onChange={handleChange} />
      <p>変換された名前: {convertedName}</p>
    </div>
  );
}

export default TestNamePage;
