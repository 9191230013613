import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
  Paper,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import TutorialModal from "../../components/navigation/TutorialModal";
import { useLoading } from "../../components/common/LoadingContext";

const tutorialImages = [
  {
    label: '実装中',
    imgPath: '/tu1.png'
  },
  {
    label: '実装中',
    imgPath: '/tu2.png'
  },
  {
    label: '実装中',
    imgPath: '/tu3.png'
  },
  {
    label: '実装中',
    imgPath: '/tu4.png'
  }
]

function AiPage() {
  const [file, setFile] = useState(null);
  const [sourceId, setSourceId] = useState("");
  const [question, setQuestion] = useState("");
  const { loading, setLoading } = useLoading();

  const [messages, setMessages] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    } else {
      console.log("ファイル選択がキャンセルされました。");
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      console.log("ファイルが選択されていません。");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload-pdf`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setSourceId(res.data.sourceId);
      setSnackbar({
        open: true,
        message: "PDFファイルをアップロードしました",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: "PDFファイルのアップロードに失敗しました",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleAskQuestion = async () => {
    // 質問をメッセージ配列に追加
    const newMessages = [...messages, { type: "question", text: question }];
    setMessages(newMessages);
    setQuestion("");

    try {
      // サーバーへの質問送信処理
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/chat-with-pdf`,
        { sourceId, question }
      );

      // サーバーからの回答をチャット履歴に追加
      const answer = res.data.content; // 仮にサーバーからの回答が content フィールドに含まれるとします
      setMessages([...newMessages, { type: "answer", text: answer }]);
    } catch (error) {
      console.error(error);
      // エラー発生時のメッセージ
      setMessages([
        ...newMessages,
        { type: "answer", text: "質問を送信できませんでした。" },
      ]);
    }
  };

  const handleDeletePDF = async () => {
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/delete-pdf`, {
        sources: [sourceId],
      });

      setSourceId("");
      setMessages([]);
      setFile("");
      setSnackbar({
        open: true,
        message: "PDFファイルを削除しました",
        severity: "success",
      });
      window.location.reload();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "PDFファイルの削除に失敗しました",
        severity: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        flexGrow: 1,
        maxWidth: 950,
        margin: "auto",
        p: 3,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        チャットでPDF
      </Typography>
      <Paper style={{ marginBottom: "0.5rem" }} elevation={3}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            variant="contained"
            component="label"
            sx={{
              my: 1,
              width: "90%",
              bgcolor: "primary.main",
              "&:hover": { bgcolor: "primary.dark" },
            }}
          >
            ファイルを選択
            <AttachFileIcon sx={{ ml: 1 }} />
            <input type="file" hidden onChange={handleFileChange} />
          </Button>

          {file && (
            <Button
              variant="contained"
              onClick={handleFileUpload}
              sx={{
                my: 2,
                width: "90%",
                bgcolor: "secondary.main",
                "&:hover": { bgcolor: "secondary.dark" },
              }}
            >
              アップロード
            </Button>
          )}
        </Box>
      </Paper>
      {/* チャット表示エリア */}
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          bgcolor: "background.paper",
          my: 2,
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent:
                message.type === "question" ? "flex-end" : "flex-start",
              my: 1,
            }}
          >
            <Box
              sx={{
                maxWidth: "70%",
                bgcolor: message.type === "question" ? "#e0f7fa" : "#fffde7",
                borderRadius: "16px",
                m: 1,
                p: 1,
                boxShadow: 1,
              }}
            >
              <Typography variant="body1">{message.text}</Typography>
            </Box>
          </Box>
        ))}
      </Box>

      {/* 質問入力フィールドと送信ボタン */}
      {sourceId && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 1,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <TextField
            sx={{ flexGrow: 1, mr: 1, bgcolor: "white", borderRadius: 1 }}
            placeholder="質問を入力..."
            fullWidth
            value={question}
            onChange={handleQuestionChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" onClick={handleAskQuestion}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      {/* PDF操作ボタン */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1, mt: 2 }}>
        <Button
          startIcon={<DeleteIcon />}
          onClick={handleDeletePDF}
          sx={{
            mr: 1,
            bgcolor: "error.light",
            "&:hover": { bgcolor: "error.main" },
            color: "white",
          }}
        >
          PDFを削除
        </Button>
      </Box>

      <TutorialModal
  images={tutorialImages}
  tutorialId="page2"
          />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AiPage;
