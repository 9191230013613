import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/login`,
        loginData
      );

      const userId = response.data.userId;
      const token = response.data.token;
      if (userId) {
        sessionStorage.setItem("userId", userId);
        sessionStorage.setItem("jwt", token);
        navigate("/dashboard");
      } else {
        console.error("userId or token is not defined in the response");
        setErrorMessage("ユーザーIDまたはトークンが応答に含まれていません");
        setOpen(true);
      }
    } catch (error) {
      if (error.response) {
        // サーバーからの応答がある場合
        console.error("APIエラー:", error.response.data);
        setErrorMessage("APIエラー: " + error.response.data.message);
        setOpen(true);
      } else if (error.request) {
        // リクエストは送られたが、応答がない場合
        console.error(
          "APIへのリクエストは成功しましたが、応答がありません:",
          error.request
        );
        setErrorMessage("APIへのリクエストは成功しましたが、応答がありません");
        setOpen(true);
      } else {
        // 何らかの理由でリクエスト自体が失敗した場合
        console.error("リクエストエラー:", error.message);
        setErrorMessage("リクエストエラー: " + error.message);
        setOpen(true);
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3} sx={{ mt: 3, padding: 3, borderRadius: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                bgcolor: "#0cc0df",
                width: 45,
                height: 45,
                boxShadow: 3,
              }}
            >
              <LockOutlinedIcon sx={{ fontSize: 32, color: "white" }} />
            </Avatar>

            <Typography component="h1" variant="h6">
              ログイン
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="メールアドレス"
                name="email"
                autoComplete="current-メールアドレス"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                autoComplete="current-パスワード"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 2,
                  fontFamily: "'Noto Sans JP', sans-serif ",
                  backgroundColor: "#0cc0df",

                  fontWeight: "bold !important",
                  "&:hover": {
                    backgroundColor: "#0097b2 !important",
                  },
                }}
              >
                ログイン
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/PasswordResetRequest" style={{fontSize:"12px",textDecoration:"none"}} >
                    パスワードを忘れましたか？
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/Register" style={{fontSize:"16px",textDecoration:"none"}} >
                    {"アカウントを作成"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMessage}
      />
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
