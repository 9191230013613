import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import LinkIcon from "@mui/icons-material/Link";

function CustomAccordion({ title, items }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper elevation={0} style={{ border: "1px solid #e0e0e0", width: "100%" }}>
          <List sx={{ padding: "0" }}>
            {items.map((item, index) => (
              <>
                <ListItemButton key={index}>
                  <Link to={item.to} style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <ListItemText primary={item.label} primaryTypographyProps={{ style: { fontSize: "14px" } }} />
                    <LinkIcon color="primary" />
                  </Link>
                </ListItemButton>
                {index < items.length - 1 && <Divider />}
              </>
            ))}
          </List>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
}

const accordionData = [
  {
    title: "認知症",
    items: [
      { to: "/Hasegawa", label: "改訂長谷川式簡易知能評価（HDS-R）" },
      { to: "/", label: "Mini-Mental State Examinaton (MMSE)※近日実装予定" },
   
    ],
  },
  {
    title: "神経内科",
    items: [
      { to: "/Yahr", label: "パーキンソン病の重症度(Hoehn-Yahr重症度分類 & 生活機能障害度)" },
      { to: "/", label: "ALS機能評価スケール改訂版(ALSFRS-R)※近日実装予定" },

    ],
  },
  {
    title: "嚥下・摂食",
    items: [
      { to: "/OHAT_J", label: "OHAT-J" },
      { to: "/GUSS", label: "Gugging Swallowing Screen(GUSS)" },
      { to: "/", label: "MASA 日本語版スコアシート※近日実装予定" },
      { to: "/", label: "反復唾液嚥下テスト※近日実装予定" },
      { to: "/", label: "改訂水飲みテスト※近日実装予定" },
      { to: "/", label: "嚥下スクリーニング(EAT-10)※近日実装予定" },
      { to: "/", label: "聖隷式嚥下質問紙※近日実装予定" },
      { to: "/", label: "フードテスト(FD)※近日実装予定" },
      { to: "/", label: "頸部聴診法※近日実装予定" },

    ],
  },
  {
    title: "栄養",
    items: [
      { to: "/", label: "簡易栄養状態評価表(MNA)" },
  
    ],
  },
  {
    title: "呼吸器",
    items: [
      { to: "/", label: "mMRC※近日実装予定" },
      { to: "/", label: "Respiratory Observation Scale(RDOS)※近日実装予定" },
      { to: "/", label: "6分間歩行テスト(6MWT)※近日実装予定" },
      { to: "/", label: "修正ボルグスケール※近日実装予定" },
      { to: "/", label: "COPD Assessment test(CAT)※近日実装予定" },
      { to: "/", label: "COPDスクリーニング質問紙(IPAG)※近日実装予定" },
 
    ],
  },
  {
    title: "皮膚",
    items: [
      { to: "/", label: "褥瘡評価 DESIN-R※近日実装予定" },
      { to: "/", label: "皮膚ブレーゲンスケール※近日実装予定" },
     
    ],
  },
  {
    title: "脳神経",
    items: [
      { to: "/", label: "ブルンストロームステージ※近日実装予定" },
      { to: "/", label: "Glasgow Coma Scale(GCS)※近日実装予定" },
      { to: "/", label: "Japan Coma Scale(JCS)※近日実装予定" },
      { to: "/", label: "Emergency Coma Scale(EGS)※近日実装予定" },
      { to: "/", label: "Validation of a new Scale(The FOUR score)※近日実装予定" },

   
    ],
  },
  {
    title: "整形外科",
    items: [
      { to: "/SeikeiTest", label: "整形外科的テスト" },
      
  
    ],
  },
  {
    title: "身体機能",
    items: [
      { to: "/ROMArm", label: "関節可動域(ROM)上肢" },
      { to: "/ROMLeg", label: "関節可動域(ROM)下肢・体幹" },
      { to: "/ROMFinger", label: "関節可動域(ROM)手指" },
      { to: "/GMT", label: "粗大筋力検査(GMT)" },
      { to: "/BBS", label: "Berg Balance Scale(BBS)" },
      { to: "/", label: "Functional Reach Test(FRT)※近日実装予定" },
   
 
    ],
  },
  {
    title: "疼痛",
    items: [
      { to: "/", label: "総合疼痛テスト※近日実装予定" },
      { to: "/", label: "短縮版マギル質問表（SF-MPQ-2）※近日実装予定" },
      { to: "/", label: "神経障害性疼痛スクリーニングテスト※近日実装予定" },
      { to: "/", label: "Pain Disability Assessment Scale(PDAS)※近日実装予定" },
      { to: "/", label: "日本語版Euro Qol※近日実装予定" },
   
    ],
  },
  {
    title: "日常生活動作",
    items: [
      { to: "/BathelIndex", label: "BathelIndex(BI)" },
      { to: "/Fim", label: "機能的自立評価法(FIM)" },
    
    ],
  },

];

export default function PhysicalTherapist() {
  return (
    <Container>
      {accordionData.map((data, index) => (
        <CustomAccordion key={index} title={data.title} items={data.items} />
      ))}
    </Container>
  );
}