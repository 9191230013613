import React from 'react';
import { Table, TableBody, TableRow, TableCell, Box, Typography } from '@mui/material';


const TotalScore = ({ formData,maxScore }) => {
  // formDataから数値フィールドの合計を計算
  const total = Object.values(formData).reduce((total, value) => {
    if (!isNaN(value) && value !== '') {
      return total + parseFloat(value);
    }
    return total;
  }, 0);

  return (
    <Box sx={{mt:3}}>
    <Table >
      <TableBody>
        <TableRow>
          <TableCell sx={{ color: "white", backgroundColor: "#5972ff",  fontWeight: 'bold',width: "26%" }}>
            合計点
          </TableCell>
          <TableCell align="left" sx={{  padding: 2 }}>
              <Box sx={{ pl: 3, pt: 1, display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5" sx={{ color: '#1976d2',fontWeight: 'bold' }}>
                  {total}
                </Typography>
                <Typography variant="body2" sx={{ ml: 2,mt:1, color: 'text.secondary' }}>
                  / {maxScore} {/* 満点を小さく表示 */}
                </Typography>
              </Box>
            </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </Box>
  );
};

export default TotalScore;
