import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";
import CustomTable from "components/common/pdfTable/CustomTable";
import axios from "axios";
import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";
import TotalScore from "../../navigation/TotalScore";

const MAX_SCORE = 56;

const BBS = () => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const createInitialFormData = () => {
    const initialFormData = {
      title: "Berg Balance Scale(BBS)",
      name: "",
      days: "",
      therapy: "",
    };

    for (let i = 1; i <= 14; i++) {
      initialFormData[`selectValue${i}`] = "";
    }

    return initialFormData;
  };

  const [formData, setFormData] = useState(createInitialFormData());

  const calculateTotalScore = () => {
    let totalScore = 0;
    for (let i = 1; i <= 14; i++) {
      // formDataの各selectValueが空文字列の場合は0を使用する
      totalScore += Number(formData[`selectValue${i}`]) || 0;
    }
    return totalScore;
  };

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,
        totalScore: calculateTotalScore().toString(),
        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/BBS`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "Berg Balance Scale(BBS)";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]);

  const handleRefresh = () => {
    setFormData(createInitialFormData());
  };

  const tableRows = [
    {
      label: "1.立ち上がり(椅子からの立ち上がり)",
      name: "selectValue1",
      options: [
        {
          value: "4",
          label: "4 手を使わずに立ち上がる事ができ、独りで保持できる",
        },
        { value: "3", label: "3 手を使えば、独りで立ち上がる事ができる" },
        { value: "2", label: "2 何度か行えば、手を使って立つ事ができる" },
        {
          value: "1",
          label: "1 立ち上がり、もしくは立位保持に少しの介助を要する",
        },
        {
          value: "0",
          label: "0 立ち上がるには、中等度または最大の介助を要する",
        },
      ],
      instruction: "指示：手を使わずに立ってください。",
    },
    {
      label: "2.立位保持",
      name: "selectValue2",
      options: [
        {
          value: "4",
          label: "4 安全に２分間立位が保持できる",
        },
        { value: "3", label: "3 見守りがあれば２分間立位が保持できる" },
        { value: "2", label: "2 介助なしで30秒間立位が保持できる" },
        { value: "1", label: "1 何度か行えば、30秒間の立位が保持できる" },
        { value: "0", label: "0 介助なしには30秒間立っていられない" },
      ],
      instruction:
        "指示：つかまらず2分間立位を保持してください。\n※2分間安全に立位保持できれば、「座位保持」の項目は満点とし、「4」の項目に進む",
    },
    {
      label: "3.座位保持（両足を床につけ、もたれずに座る）",
      name: "selectValue3",
      options: [
        {
          value: "4",
          label: "4 安全に２分間座位が保持できる",
        },
        { value: "3", label: "3 見守りがあれば2分間座位が保持できる" },
        { value: "2", label: "2 30秒間の座位が保持できる" },
        { value: "1", label: "1 10秒間の座位が保持できる" },
        { value: "0", label: "0 介助なしには10秒間の座位が保持ができない" },
      ],
      instruction: "指示：腕を組んで2分間座ってください。",
    },
    {
      label: "4.着座(立位から座位へ）",
      name: "selectValue4",
      options: [
        {
          value: "4",
          label: "4 ほとんど手を使わずに、安全に座ることができる",
        },
        { value: "3", label: "3 手を使って、着座を制御している" },
        { value: "2", label: "2 両下肢後面を椅子につけて、着座を制御している" },
        { value: "1", label: "1 独りで座れるが、着座が制御できない" },
        { value: "0", label: "0 座るのに介助を要する" },
      ],
      instruction: "指示：座ってください。",
    },
    {
      label: "5.移乗動作",
      name: "selectValue5",
      options: [
        {
          value: "4",
          label: "4 手をわずかに使うだけで安全に移乗ができる",
        },
        { value: "3", label: "3 手をしっかり使えば安全に移乗ができる" },
        { value: "2", label: "2 口頭指示、もしくは見守りがあれば移乗ができる" },
        { value: "1", label: "1 移乗に介助者1名を要する" },
        { value: "0", label: "0 安全確保のために2名の介助者を要する" },
      ],
      instruction:
        "指示：軸回旋できるよう椅子を調整します。\n肘掛けを使って別䛾椅子へ移乗、さらに肘掛けなしで別䛾椅子へ移乗します。\n2脚の椅子（肘掛けありと肘掛けなし）か、もしくはベッドと椅子を使ってください。",
    },
    {
      label: "6.閉眼での立位保持",
      name: "selectValue6",
      options: [
        {
          value: "4",
          label: "4 安全に10秒間閉眼立位が保持できる",
        },
        { value: "3", label: "3 見守りがあれば、10秒間閉眼立位が保持できる" },
        { value: "2", label: "2 3秒間の閉眼立位が保持できる" },
        {
          value: "1",
          label: "1 3秒間閉眼していられないが、安定して立位が保持できる",
        },
        { value: "0", label: "0 転倒しないよう介助を要する" },
      ],
      instruction: "指示：目を閉じて10秒間立ってください。",
    },
    {
      label: "7.両足を揃えての立位保持",
      name: "selectValue7",
      options: [
        {
          value: "4",
          label: "4 両足を揃えて、独りで１分間安全に立位が保持できる",
        },
        {
          value: "3",
          label: "3 両足を揃えて、見守りの下で１分間立位が保持できる",
        },
        {
          value: "2",
          label: "2 両足を揃えて独りで立位が保持できるが、３０秒保てない",
        },
        {
          value: "1",
          label: "1 両足を揃えての立位保持には介助を要するが、15秒間可能",
        },
        {
          value: "0",
          label: "0 両足を揃えての立位保持に介助を要し、15秒間保てない",
        },
      ],
      instruction: "指示：足を揃えて、何もつかまらずに立っていてください。",
    },
    {
      label: "8.両手前方リーチ",
      name: "selectValue8",
      options: [
        {
          value: "4",
          label: "4 前方に25cmに届く",
        },
        { value: "3", label: "3 前方に12cm届く" },
        { value: "2", label: "2 前方に5cm届く" },
        { value: "1", label: "1 前方へ出せるが、見守りが必要" },
        {
          value: "0",
          label: "0 行おうとするとバランスを崩すか、かなりの介助を要する",
        },
      ],
      instruction:
        "指示：片腕を90°挙げてください。\n指を伸ばした状態でできるだけ前方に手を伸ばしてください。（可能なら、体幹回旋を防ぐため両腕で行う）",
    },
    {
      label: "9.拾い上げ（床の物を拾う）",
      name: "selectValue9",
      options: [
        {
          value: "4",
          label: "4 安全にかつ簡単に拾う事ができる",
        },
        { value: "3", label: "3 拾う事はできるが、見守りが必要" },
        {
          value: "2",
          label: "2 拾う事ができないが、2～5cm手前まで手を伸ばす事はできる",
        },
        { value: "1", label: "1 拾う事ができず、検査を行うには見守りが必要" },
        {
          value: "0",
          label:
            "0 行えないか、もしくはバランスを崩したり転倒しないように介助を要する",
        },
      ],
      instruction: "指示：足の前に置いた靴やスリッパを拾ってください。",
    },
    {
      label: "10.左右の肩越しに後ろを振り向く",
      name: "selectValue10",
      options: [
        {
          value: "4",
          label: "4 両側から後方を見る事ができ、うまく体重移動もできる",
        },
        {
          value: "3",
          label:
            "3 片方からなら後方を見る事ができるが、もう一方では体重移動が少ない",
        },
        { value: "2", label: "2 横向きまでなら回旋でき、バランスは維持できる" },
        { value: "1", label: "1 回旋には見守りを要する" },
        {
          value: "0",
          label: "0 バランスを失ったり転倒しないように、介助を要する",
        },
      ],
      instruction:
        "指示：左の肩越しに後ろを見てください。右でも繰り返してください。",
    },
    {
      label: "11.360°方向転換（1回転）",
      name: "selectValue11",
      options: [
        {
          value: "4",
          label: "4 4秒以内に安全に360°回る事ができる",
        },
        { value: "3", label: "3 片方にのみ4秒以内に安全に360°回る事ができる" },
        { value: "2", label: "2 ゆっくりと360°回る事ができる" },
        { value: "1", label: "1 近接の見守りか口頭指示が必要" },
        { value: "0", label: "0 回る際に介助を要する" },
      ],
      instruction:
        "指示：その場で1回転まわってください。逆方向もお願いします。",
    },
    {
      label: "12.段差交互踏み換え",
      name: "selectValue12",
      options: [
        {
          value: "4",
          label: "4 20秒間に独りで8回完全に踏み換える事ができる",
        },
        {
          value: "3",
          label: "3 独りで8回踏み換える事ができるが、20秒を超える",
        },
        { value: "2", label: "2 見守りのみで、完全に４回踏み換えできる" },
        {
          value: "1",
          label: "1 少しの補助があれば、完全に3回以上踏み換えできる",
        },
        {
          value: "0",
          label: "0 転倒しないためには介助を要する、もしくは行えない",
        },
      ],
      instruction: "指示：手を使わずに立ってください。",
    },
    {
      label: "13.継ぎ足立位（片足を前に出した立位保持）",
      name: "selectValue13",
      options: [
        {
          value: "4",
          label: "4 独りで継ぎ足を行い、30秒保持できる",
        },
        { value: "3", label: "3 独りで前方に足を出し、30秒保持できる" },
        { value: "2", label: "2 独りで前方に小さく足を出し、30秒保持できる" },
        { value: "1", label: "1 足を出すには介助を要するが、15秒保持できる" },
        {
          value: "0",
          label: "0 足を出す際、もしくは立っている時にバランスを崩してしまう",
        },
      ],
      instruction: "指示：片方の足を、もう一方の足のすぐ前に接地してください。",
    },
    {
      label: "14.片脚立位",
      name: "selectValue14",
      options: [
        {
          value: "4",
          label: "4 独りで片脚を上げて、10秒超保持できる",
        },
        { value: "3", label: "3 独りで片脚を上げて、5～10秒保持できる" },
        { value: "2", label: "2 独りで片脚を上げて、３秒以上保持できない" },
        { value: "1", label: "1 独りで片脚できるが、３秒まで保持できない" },
        { value: "0", label: "0 転倒を防ぐには介助を要する" },
      ],
      instruction: "指示：つかまらずに、出来るだけ長く片脚で立ってください。",
    },
  ];

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Berg Balance Scale (BBS)
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
        <CustomTable
          rows={tableRows}
          formData={formData}
          handleChange={handleChange}
        />
      </Box>
      <TotalScore formData={formData} maxScore={MAX_SCORE} />
      <Button
        variant="contained"
        color="success"
        sx={{
          position: "fixed",
          right: [10, 50, 100],
          top: [150, 150, 100],
          size: ["small", "medium", "large"],
          zIndex: 12,
        }}
        onClick={handleSaveDraft}
      >
        一時保存
      </Button>
      <ImageModal
        imageUrl={`${process.env.PUBLIC_URL}/BBS.png`} // テンプレートリテラルを使用
        imageTitle="Berg Balance Scale(BBS)"
        buttonText="PDFを確認"
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      {pdfFilePath && (
        <PdfModal
          open={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfFilePath={pdfFilePath}
        />
      )}
      <FixedBottomNavigation editPDF={editPDF} handleRefresh={handleRefresh} />

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default BBS;
