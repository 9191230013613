import React from "react";
import {
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import FooterMenu from "./TopPages/FooterMenu";
import FAQ from "./TopPages/FAQ";
import HomeService from "./TopPages/HomeService";
import Donation from "./TopPages/Donation";


function Home() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const features = [
    {
      title: "時短でスムーズな作業",
      description:
        "手書きの手間を省き、スマホでパパッと入力。より多くの時間を患者ケアに充てることができます。",
      image: "1.png",
    },

    {
      title: "ミス減で安心",
      description:
        "誤字や間違いを減らして、データはいつもクリア。評価用紙をサクッと作れます。",
      image: "2.png",
    },
    {
      title: "いつ・どこでもアクセス",
      description: "訪問先でも問題なし。途中でも一時保存可能！",
      image: "3.png",
    },

    {
      title: "エコで地球に優しい",
      description:
        "紙の使用を減らして環境保護。デジタル化でグリーンな医療環境を。",
      image: "4.png",
    },
  ];

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
        }}
      >
        {isSmallScreen ? (
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/homemini.png`}
              alt="PDF Mini"
              style={{ width: "100%", height: "auto", display: "block" }} // display: block を追加
            />

            <Box
              sx={{
                height: "130px",
                backgroundColor: "#fafdff",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                pt: 3,
              }}
            >
              <Typography variant="body1" sx={{ mt: 1, fontWeight: "bold" }}>
                登録ですぐ<span style={{ color: "red" }}>評価用紙キット</span>
                を利用できます
              </Typography>
            </Box>
          </div>
        ) : (
          <video width="100%" height="auto" autoPlay muted loop>
            <source
              src={`${process.env.PUBLIC_URL}/pdf.mp4`}
              type="video/mp4"
            />
            お使いのブラウザは動画をサポートしていません。
          </video>
        )}
        <Button
          component={Link}
          to="/Login"
          sx={{
            py: isSmallScreen ? 1 : 1,
            position: "absolute",
            top: isSmallScreen ? "91%" : "76%",
            left: isSmallScreen ? "50%" : "25%",
            transform: "translate(-50%, -50%)",
            width: isSmallScreen ? "50%" : "25%",
            color: "#FFFFFF",
            backgroundColor: "#0F29FC",
            fontSize: isSmallScreen ? "19px" : "20px ",
            fontWeight: "bold",
            boxShadow: "0px 4px 6px #888888 ",
            "&:hover": {
              backgroundColor: "#9FA9FD ",
            },
          }}
        >
          使ってみる
        </Button>
      </Box>
      
      <Box
        id="how"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          paddingTop: isSmallScreen ? "30px" : "50px",

          borderRadius: "8px",
         
        }}
      >
        <Typography
          variant={isSmallScreen ? "h4" : "h2"}
          component="h2"
          sx={{
            fontWeight: "bold",
            color: "#1976d2",
            mt: 5,
            mb: 1,
            borderBottom: "2px solid #1976d2", // 下線を追加
            width: "fit-content", // 幅を内容に合わせる
            pb: 1,
          }}
        >
          HOW TO
        </Typography>
        <Typography
          variant={isSmallScreen ? "h6" : "h4"}
          sx={{
            fontWeight: "bold",
            color: "#333333",
            mb: 5,
          }}
        >
          評価用紙キットを使う理由
        </Typography>
        <Box sx={{ p: 1, mx: 1, overflow: "hidden" }}>
          <Grid container spacing={2}>
            {features.map((feature, index) => (
              <Grid item xs={6} sm={6} md={3} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: isSmallScreen ? 0.5 : 3,
                    mx: 2,
                    maxWidth: isSmallScreen ? "260px" : "auto",
                    margin: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1.5px solid #1976d2",
                      borderRadius: "5px",
                      p: isSmallScreen ? 1 : 1,
                      bgcolor: "background.paper",
                      mb: isSmallScreen ? 2 : 3,
                      height: isSmallScreen ? "15px" : "45px",
                      width: isSmallScreen ? "150px" : "230px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      component="h3"
                      sx={{
                        fontWeight: "bold",
                        color: "#1976d2",
                        fontSize: isSmallScreen ? "13px" : "20px",
                      }}
                    >
                      {feature.title}
                    </Typography>
                  </Box>
                  <img
                    src={`${process.env.PUBLIC_URL}/${feature.image}`}
                    alt={feature.title}
                    style={{
                      width: isSmallScreen ? "160px" : "270px",
                      height: isSmallScreen ? "90px" : "180px",

                      objectFit: "cover",
                      border: "1.5px solid #1976d2",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      maxWidth: "260px",
                      margin: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        mt: isSmallScreen ? 2 : 4,
                        lineHeight: "1.8",
                        fontWeight: "bold",
                        color: "#424242",
                        fontSize: isSmallScreen ? "11px" : "20px",
                        textAlign: "center",
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          id="service"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            mt: 4,
          }}
        >
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            component="h2"
            sx={{
              fontWeight: "bold",
              color: "#1976d2",

              mt: 5,
              mb: 1,
              borderBottom: "2px solid #1976d2", // 下線を追加
              width: "fit-content", // 幅を内容に合わせる
              pb: 1,
            }}
          >
            SERVICE
          </Typography>
          <Typography
            variant={isSmallScreen ? "h6" : "h4"}
            sx={{
              fontWeight: "bold",
              color: "#333333",
              mb: isSmallScreen ? 1 : 5,
            }}
          >
            評価用紙キットができること
          </Typography>
          <HomeService></HomeService>
        </Box>

        <Box
          id="qa"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            mt: 4,
          }}
        >
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            component="h2"
            sx={{
              fontWeight: "bold",
              color: "#1976d2",

              mt: 5,
              mb: 1,
              borderBottom: "2px solid #1976d2", // 下線を追加
              width: "fit-content", // 幅を内容に合わせる
              pb: 1,
            }}
          >
            Q&A
          </Typography>
          <Typography
            variant={isSmallScreen ? "h6" : "h4"}
            sx={{
              fontWeight: "bold",
              color: "#333333",
              mb: isSmallScreen ? 1 : 5,
            }}
          >
            よくある質問
          </Typography>
        </Box>

        <FAQ></FAQ>

        <Box
          id="donation"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            mt: 4,
          }}
        >
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            component="h2"
            sx={{
              fontWeight: "bold",
              color: "#1976d2",

              mt: 5,
              mb: 1,
              borderBottom: "2px solid #1976d2", // 下線を追加
              width: "fit-content", // 幅を内容に合わせる
              pb: 1,
            }}
          >
            DONATION
          </Typography>
          <Typography
            variant={isSmallScreen ? "h6" : "h4"}
            sx={{
              fontWeight: "bold",
              color: "#333333",
              mb: isSmallScreen ? 5 : 5,
            }}
          >
            寄付・評価用紙の提供の依頼
          </Typography>
          <Donation></Donation>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            mt: 4,
          }}
        >
          <Typography
            variant={isSmallScreen ? "h5" : "h2"}
            component="h2"
            sx={{
              fontWeight: "bold",
              color: "black",

              mt: 10,

              borderBottom: "2px solid black",

              pb: 1,
            }}
          >
            ぜひ現場でご利用ください。
          </Typography>
          <Button
            component={Link}
            to="/Register"
            variant="contained"
            sx={{
              mt: 4,
              mb: 15,
              py: 2,

              width: isSmallScreen ? "45%" : "30%",
              color: "#FFFFFF",
              backgroundColor: "#0F29FC",
              fontSize: isSmallScreen ? "20px " : "40px",
              fontWeight: "bold ",
              boxShadow: "0px 4px 6px #888888",
              "&:hover": {
                backgroundColor: "#9FA9FD ",
              },
            }}
          >
            いますぐ登録
          </Button>
        </Box>
      </Box>
      <FooterMenu></FooterMenu>
    </>
  );
}

export default Home;
