import React,{useState} from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import EditNoteIcon from "@mui/icons-material/EditNote";
import RefreshIcon from "@mui/icons-material/Refresh";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ConfirmModal from "./ConfirmModal";

export default function FixedBottomNavigation({ editPDF, handleRefresh }) {
  const [value, setValue] = React.useState(0);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);


  const handleActionClick = (action) => {
    
    if (action === "editPDF") {
      editPDF();
     
    } else if (action === "handleRefresh") {
      setIsResetModalOpen(true);
    }
  };

  const handleConfirmReset = () => {
    handleRefresh();
    setIsResetModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsResetModalOpen(false);
  };
  const handlePDFCreationClick = () => {
    setIsConfirmModalOpen(true); // 「PDFを作成」ボタンクリック時に新しいモーダルを表示
  };

  const handleConfirmCreation = () => {
    editPDF();
    setIsConfirmModalOpen(false);
  };

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
 

  return (
    <Box sx={{ pb: 8 }}>
      <CssBaseline />
      <Paper
        sx={{ 
          position: "fixed", 
          bottom: 0, 
          left: 0,
          right: 0 ,
          zIndex: 10,
          }}
        elevation={6}
      >
        <BottomNavigation
          showLabels
          value={value}
          sx={{
            backgroundColor: "white", 
          }}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="PDFを作成"
            icon={<EditNoteIcon />}
            onClick={handlePDFCreationClick} 
            sx={{
              color: "blue",
              "&:hover": {
                color: "black",
              },
            }}
          />
          <BottomNavigationAction
            label="リセット"
            icon={<RefreshIcon />}
            onClick={() => handleActionClick("handleRefresh")}
            sx={{
              color: "black",
              "&:hover": {
                color: "red",
              },
            }}
          />
        </BottomNavigation>

        <Dialog
          open={isResetModalOpen}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="sm"
          sx={{zIndex:2000}}
        >
          <DialogTitle>本当にリセットしますか？</DialogTitle>
          <DialogContent>
            <DialogContentText color="error">
              リセット後は一時保存、確定をしてください。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseModal} color="primary">
              キャンセル
            </Button>
            <Button variant="outlined" onClick={handleConfirmReset} color="error">
            リセット
            </Button>
          </DialogActions>
        </Dialog>

        {/* PDF編集モーダル */}
        <ConfirmModal 
       
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleConfirmCreation}
      />
        
      </Paper>
    </Box>
  );
}

