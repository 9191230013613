import React from 'react';
import { TextField, TableCell, TableRow, Typography, Grid } from '@mui/material';

const NumberTableRow = ({ row, formData, handleChange }) => {
  return (
    <TableRow>
      <TableCell sx={{ backgroundColor: "#5972ff", width: "28%", color: "white" }}>
        {row.label}
      </TableCell>
      <TableCell align="left">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="body2">右：</Typography>
            <TextField
              type="number"
              name={`${row.name}Right`}
              value={formData[`${row.name}Right`]}
              onChange={handleChange}
              variant="outlined"
              size="small"
              sx={{ minWidth: 80 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">左：</Typography>
            <TextField
              type="number"
              name={`${row.name}Left`}
              value={formData[`${row.name}Left`]}
              onChange={handleChange}
              variant="outlined"
              size="small"
              sx={{ minWidth: 80 }}
            />
          </Grid>
        </Grid>
        {row.instruction && (
          <Typography
            sx={{ color: "red", fontWeight: "bold", fontSize: "12px", marginTop: "8px" }}
          >
            {row.instruction.split("\n").map((str, index, array) => (
              <span key={index}>
                {str}
                {index !== array.length - 1 && <br />}
              </span>
            ))}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default NumberTableRow;
