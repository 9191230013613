import React from 'react';
import { Box, Typography, useMediaQuery, useTheme, } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';



const FAQ = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ maxWidth: '800px', margin: 'auto', ml: 2, mr: 2 }}>
    
      {/* 各質問と回答 */}
      <Typography variant={isSmallScreen ? "h6" : "h4"} gutterBottom>評価用紙キットについて</Typography>
      <Typography variant={isSmallScreen ? "body1" : "h5"} gutterBottom component="div">
        <strong><QuizIcon color="primary" fontSize="large" /> 評価用紙の被験者の氏名はどうすればいいですか</strong>
        <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ mt: 3 ,mb:1,ml:2}}>
          評価用紙キットでは氏名を入力すると「○田○郎」のように自動変換されます。
        </Typography>
        <Typography variant={isSmallScreen ? "body2" : "body2"} sx={{mb:5,ml:2,color:'red'}}>
          ※利用規約を遵守して利用してください。
        </Typography>
      </Typography>
      <Typography variant={isSmallScreen ? "body1" : "h5"} gutterBottom component="div">
        <strong><QuizIcon color="primary" fontSize="large" /> 作成した評価用紙は保存できますか？</strong>
        <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ mt: 3 ,mb:5,ml:2}}>
          はい。可能です。<br/>
          作成途中の【一時保存】、作成後のPDFも「過去のPDF」で一覧で確認可能です。<br/>
          詳しくは、評価用紙キットの使い方をご覧ください.
        </Typography>
      </Typography>
      <Typography variant={isSmallScreen ? "body1" : "h5"} gutterBottom component="div">
        <strong><QuizIcon color="primary" fontSize="large" /> 作成した評価用紙を印刷できますか</strong>
        <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ mt: 3 ,mb:1,ml:2}}>
          はい。可能です。<br/>
          利用する機器に合わせて保存し印刷してください。<br/>
          Android、iPhoneの場合は、プリンターと接続し簡単に印刷可能です。
        </Typography>
      </Typography>
      <Typography variant={isSmallScreen ? "body2" : "body2"} sx={{mb:5,ml:2,color:'red'}}>
          ※コンビニ等、公共の場所での印刷は控えてください。
        </Typography>


    

    </Box>
  );
};

export default FAQ;

