import React, { useState } from "react";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";

const defaultTheme = createTheme();

function PasswordResetRequest() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success"); // 'error' または 'info' にもできます

  const navigate = useNavigate();

  const validateEmail = (email) => {
    return /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)
      ? ""
      : "有効なメールアドレスを入力してください。";
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    setError(validateEmail(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const emailError = validateEmail(email);
    if (emailError) {
      setError(emailError);
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password-reset-request`,
        { email }
      );
      setAlertMessage("メールを送信しました。メールをご確認ください。");
      setAlertSeverity("success");
      setTimeout(() => {
        navigate("/login"); // 一定時間後にログイン画面にリダイレクト
      }, 3000); // 3秒後にリダイレクト
    } catch (error) {
      setAlertMessage(error.response.data);
      setAlertSeverity("error");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3} sx={{ mt: 3, padding: 3, borderRadius: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                bgcolor: "#0cc0df",
                width: 45,
                height: 45,
                boxShadow: 3,
              }}
            >
              <LockOutlinedIcon sx={{ fontSize: 32, color: "white" }} />
            </Avatar>
            <Typography component="h1" variant="h6">
              パスワードのリセット
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="メールアドレス"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={handleChange}
                error={!!error} // エラーがある場合にTextFieldのスタイルを変更
                helperText={error} // エラーメッセージを表示
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontFamily: "'Noto Sans JP', sans-serif !important",
                  backgroundColor: "#0cc0df",
                  fontWeight: "bold !important",
                  "&:hover": { backgroundColor: "#0097b2 !important" },
                }}
              >
                リセットリンクを送信
              </Button>
              {alertMessage && (
                <Alert severity={alertSeverity} sx={{ mt: 2 }}>
                  {alertMessage}
                </Alert>
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default PasswordResetRequest;
