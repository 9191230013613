import React, { useState, useEffect } from "react";
import "./index.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { LoadingProvider } from "./components/common/LoadingContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SearchAppBar from "./components/navigation/appBar";
import DefaultAppBar from "./components/navigation/DefaultAppBar";
import PrivateRoute from "./components/navigation/PrivateRoute";

import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import PasswordResetRequest from "./components/auth/PssswordResetRequest.js";
import PasswordReset from "./components/auth/PasswordReset.js";
import ContactForm from "components/auth/ContactForm";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import UserHome from "./pages/Paper/UserHome";

import Nurse from "./pages/EachPages/Nurse";
import Physical from "./pages/EachPages/PhysicalTherapist";
import Occupational from "./pages/EachPages/OccupationalTherapist";
import Speech from "./pages/EachPages/SpeechTherapist";
import AiPage from "./pages/AI/AiPage";
import Term from "pages/TopPages/Term";
import PrivacyPolicy from "pages/TopPages/PrivacyPolicy";

import Box from "@mui/material/Box";

import Hasegawa from "./components/pdf-file/Dementia/Hasegawa";
import OHAT_J from "./components/pdf-file/Swallowing/OHAT_J";
import SeikeiTest from "./components/pdf-file/physical/Seikei_Test";
import BathelIndex from "components/pdf-file/ADL/BathelIndex";
import Fim from "components/pdf-file/ADL/Fim";
import BBS from "components/pdf-file/BodyFunction/BBS";
import GMT from "components/pdf-file/BodyFunction/GMT";
import ROMArm from "components/pdf-file/BodyFunction/ROMArm";
import ROMLeg from "components/pdf-file/BodyFunction/ROMLeg";
import ROMFinger from "components/pdf-file/BodyFunction/ROMFinger";
import Yahr from "components/pdf-file/Neurology/Yahr";
import GUSS from "components/pdf-file/Swallowing/GUSS";
import ALSFRS from "components/pdf-file/Neurology/ALSFRS";
import MNA from "components/pdf-file/Nutrition/MNA";

import Test from "components/pdf-file/test";

// 新しいテーマの作成
const theme = createTheme({
  palette: {
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          minWidth: 230,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          whiteSpace: "normal",
          overflowWrap: "break-word",
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        with: "190px",
      },
    },
  },
});

const Navigation = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const hideForPaths = [
    "/",
    "/Login",
    "/Register",
    "/PasswordResetRequest",
    "/PasswordReset",
    "/ContactForm",
    "/Term",
    "/PrivacyPolicy",
  ];
  const noAppBarPaths = ["/FileUpload"];

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  if (noAppBarPaths.includes(currentPath)) {
    return null; // AppBarを何も表示しない
  }

  if (hideForPaths.includes(currentPath)) {
    return <DefaultAppBar />;
  }

  return <SearchAppBar />;
};
function App() {
  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <LoadingProvider>
            <Box
              style={{
                backgroundColor: theme.palette.background.default,
                minHeight: "100vh",
              }}
            >
              <Navigation />

              <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/Login" element={<Login />} />
                <Route path="/Register" element={<Register />} />
                <Route
                  path="/PasswordResetRequest"
                  element={<PasswordResetRequest />}
                />
                <Route path="/PasswordReset" element={<PasswordReset />} />
                <Route path="/ContactForm" element={<ContactForm />} />
                <Route path="/Term" element={<Term />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/Test" element={<Test />} />
                <Route path="/ALSFRS" element={<ALSFRS />} />
                <Route path="/MNA" element={<MNA />} />
                <Route
                  path="/BBS"
                  element={
                    <PrivateRoute>
                      <BBS />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/ROMArm"
                  element={
                    <PrivateRoute>
                      <ROMArm />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/GMT"
                  element={
                    <PrivateRoute>
                      <GMT />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/ROMLeg"
                  element={
                    <PrivateRoute>
                      <ROMLeg />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/ROMFinger"
                  element={
                    <PrivateRoute>
                      <ROMFinger />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Yahr"
                  element={
                    <PrivateRoute>
                      <Yahr />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/GUSS"
                  element={
                    <PrivateRoute>
                      <GUSS />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/BBS"
                  element={
                    <PrivateRoute>
                      <BBS />
                    </PrivateRoute>
                  }
                />

                <Route path="/Dashboard" element={<Dashboard />} />
                <Route
                  path="/UserHome"
                  element={
                    <PrivateRoute>
                      <UserHome />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/AiPage"
                  element={
                    <PrivateRoute>
                      <AiPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/Nurse"
                  element={
                    <PrivateRoute>
                      <Nurse />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Physical"
                  element={
                    <PrivateRoute>
                      <Physical />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Occupational"
                  element={
                    <PrivateRoute>
                      <Occupational />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Speech"
                  element={
                    <PrivateRoute>
                      <Speech />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/Hasegawa"
                  element={
                    <PrivateRoute>
                      <Hasegawa />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/OHAT_J"
                  element={
                    <PrivateRoute>
                      <OHAT_J />
                    </PrivateRoute>
                  }
                />
                <Route path="/SeikeiTest" element={<SeikeiTest />} />
                <Route
                  path="/BathelIndex"
                  element={
                    <PrivateRoute>
                      <BathelIndex />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Fim"
                  element={
                    <PrivateRoute>
                      <Fim />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </Box>
          </LoadingProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
