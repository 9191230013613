import React from "react";
import { Box, Container, Grid, Typography,Button } from "@mui/material";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

const FooterMenu = () => {
  return (
    <Box component="footer" sx={{ width: "100%" }}>
      {/* メインフッター部分 */}
      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          pt: "5rem",
          pb: "3rem",
        }}
      >
        <Container maxWidth="lg">
        <Grid container spacing={3}>
  {/* 1列目: ロゴとアプリ名 */}
  <Grid item xs={12} sm={4}>
    <Box
      component="img"
      src={process.env.PUBLIC_URL + "/pdf-app-logo.png"}
      alt="PDF App Logo"
      sx={{ mr: 0.8, width: 65, height: 65 ,mb:1}}
    />
    <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
      評価用紙アプリ
    </Typography>
  </Grid>

  {/* 2列目: ページ内リンク */}
  <Grid item xs={12} sm={4}>
  <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 1 }}>
    MENU
  </Typography>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <AnchorLink href="#how" style={{ color: 'white', textDecoration: 'none', marginBottom: '8px', fontWeight: 'medium' }}>
        - HOW TO
      </AnchorLink>
      <AnchorLink href="#service" style={{ color: 'white', textDecoration: 'none', marginBottom: '8px', fontWeight: 'medium' }}>
        - SERVICE
      </AnchorLink>
      <AnchorLink href="#qa" style={{ color: 'white', textDecoration: 'none', marginBottom: '8px', fontWeight: 'medium' }}>
        - Q&A
      </AnchorLink>
      <AnchorLink href="#donation" style={{ color: 'white', textDecoration: 'none', marginBottom: '8px', fontWeight: 'medium' }}>
        - DONATION
      </AnchorLink>
    </Box>
</Grid>


  {/* 3列目: お問い合わせと利用規約 */}
  <Grid item xs={12} sm={4}>
  <Button
        variant="outlined"
        component={Link}
        to="/ContactForm"
        color="inherit"
        sx={{ width: '100%', mb: 2 }}
      >
        お問い合わせ
      </Button>
      <br />
      <Button
        variant="outlined"
        component={Link}
        to="/Term"
        color="inherit"
        sx={{ width: '100%', mb: 2 }}
      >
        利用規約
      </Button>
      <br />
      <Button
        variant="outlined"
        component={Link}
        to="/PrivacyPolicy"
        color="inherit"
        sx={{ width: '100%' }}
      >
        プライバシーポリシー
      </Button>
  </Grid>
</Grid>

        </Container>
        <Box sx={{ mt: 10 }}>
          <Typography variant="body2" align="center">
            © {new Date().getFullYear()} 評価用紙キット All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FooterMenu;
