import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";
import CustomTable from "components/common/pdfTable/CustomTable";
import axios from "axios";
import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";
import TotalScore from "../../navigation/TotalScore";

const MAX_SCORE = 48;

const ALSFRS = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [isGastrostomy, setIsGastrostomy] = useState(false);
  const createInitialFormData = () => {
    const initialFormData = {
      title: "ALS機能評価スケール改訂版(ALSFRS-R)",
      name: "",
      days: "",
      therapy: "",
    };

    for (let i = 1; i <= 12; i++) {
      initialFormData[`selectValue${i}`] = "";
    }

    return initialFormData;
  };

  const [formData, setFormData] = useState(createInitialFormData());

  const calculateTotalScore = () => {
    let totalScore = 0;
    for (let i = 1; i <= 12; i++) {
      // formDataの各selectValueが空文字列の場合は0を使用する
      totalScore += Number(formData[`selectValue${i}`]) || 0;
    }
    return totalScore;
  };

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }
  const handleChangeGastrostomy = (event) => {
    setIsGastrostomy(event.target.checked);
  };

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,
        totalScore: calculateTotalScore().toString(),
        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/ALSFRS`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "ALS機能評価スケール改訂版(ALSFRS-R)";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]);

  const handleRefresh = () => {
    setFormData(createInitialFormData());
  };

  const tableRows1 = useMemo(
    () => [
      {
        label: "言語",
        name: "selectValue1",
        options: [
          {
            value: "4",
            label: "4 会話は正常",
          },
          { value: "3", label: "3 会話障害が認められる" },
          { value: "2", label: "2 繰り返し聞くと意味がわかる" },
          {
            value: "1",
            label: "1 声以外の伝達手段と会話を併用",
          },
          {
            value: "0",
            label: "0 実用的会話の喪失",
          },
        ],
      },
      {
        label: "唾液分泌",
        name: "selectValue2",
        options: [
          {
            value: "4",
            label: "4 正常",
          },
          {
            value: "3",
            label:
              "3 口内の唾液はわずかだが、明らかに過剰（夜間はよだれが垂れる事がある）",
          },
          {
            value: "2",
            label: "2 中等度に過剰な唾液（わずかによだれが垂れる事がある）",
          },
          { value: "1", label: "1 顕著に過剰な唾液(よだれが垂れる)" },
          {
            value: "0",
            label:
              "0 著しいよだれ（絶えずティッシュペーパーやハンカチが必要となる）",
          },
        ],
      },
      {
        label: "嚥下",
        name: "selectValue3",
        options: [
          {
            value: "4",
            label: "4 正常な食事習慣",
          },
          { value: "3", label: "3 初期の摂食障害(時に食物を喉に詰まらせる)" },
          { value: "2", label: "2 食物の内容が変化（継続して食べられない）" },
          { value: "1", label: "1 補助的なチューブ栄養を必要とする" },
          { value: "0", label: "0 全面的に非経口性または腸管性栄養" },
        ],
      },
      {
        label: "書字",
        name: "selectValue4",
        options: [
          {
            value: "4",
            label: "4 正常",
          },
          {
            value: "3",
            label: "3 遅い、または書きなぐる（全ての単語が判読可能）",
          },
          { value: "2", label: "2 一部の単語が判読不可能" },
          { value: "1", label: "1 ペンは握れるが、字が書けない" },
          { value: "0", label: "0 ペンが握れない" },
        ],
      },
    ],
    []
  );

  const tableRows2 = useMemo(
    () => [
      {
        label: "摂食動作",
        name: "selectValue5",
        options: isGastrostomy
          ? [
              {
                value: "4",
                label: "4 正常",
              },
              { value: "3", label: "3 ぎこちないが全ての指先の作業ができる" },
              {
                value: "2",
                label: "2 ボタンやファスナーを止めるのにある程度手助けが必要",
              },
              {
                value: "1",
                label:
                  "1 介護者にわずかに面倒をかける（身の回りの動作に手助けが必要）",
              },
              { value: "0", label: "0 全く指先の動作ができない" },
            ]
          : [
              {
                value: "4",
                label: "4 正常",
              },
              {
                value: "3",
                label: "3 幾分遅く、ぎこちないが他人の助けを必要としない",
              },
              {
                value: "2",
                label: "2 フォーク・スプーンは使えるが、箸は使えない",
              },
              {
                value: "1",
                label:
                  "1 食物は誰かに切ってもらうが、フォークまたはスプーンで食べる事ができる",
              },
              { value: "0", label: "0 誰かに食べさせて貰う必要がある" },
            ],
        instruction: "チェックで胃瘻の有無を決めてください",
      },
    ],

    [isGastrostomy]
  );

  const tableRows3 = useMemo(
    () => [
      {
        label: "着衣、身の回りの動作",
        name: "selectValue6",
        options: [
          {
            value: "4",
            label: "4 障害なく正常に着る",
          },
          {
            value: "3",
            label: "3 努力を要するが（あるいは効率は悪いが）一人で完全にできる",
          },
          { value: "2", label: "2 時折、手助けまたは代わりに方法が必要" },
          {
            value: "1",
            label: "1 身の回りの動作に手助けが必要",
          },
          { value: "0", label: "0 全面的に他人に依存" },
        ],
      },
      {
        label: "寝床での動作",
        name: "selectValue7",
        options: [
          {
            value: "4",
            label: "4 正常",
          },
          {
            value: "3",
            label: "3 幾分遅く、ぎこちないが他人の助けを必要としない",
          },
          {
            value: "2",
            label: "2 独りで寝返ったり、寝具を整えられるが非常に苦労する",
          },
          {
            value: "1",
            label:
              "1 寝返りを始めることはできるが、独りで寝返ったり、寝具を整える事ができない",
          },
          {
            value: "0",
            label: "0 自分にはどうすることもできない",
          },
        ],
      },
      {
        label: "歩行",
        name: "selectValue8",
        options: [
          {
            value: "4",
            label: "4 正常",
          },
          { value: "3", label: "3 やや歩行が困難" },
          { value: "2", label: "2 補助歩行" },
          { value: "1", label: "1 歩行は不可能" },
          {
            value: "0",
            label: "0 脚を動かす事ができない",
          },
        ],
      },
      {
        label: "階段をのぼる",
        name: "selectValue9",
        options: [
          {
            value: "4",
            label: "4 正常",
          },
          { value: "3", label: "3 遅い" },
          {
            value: "2",
            label: "2 軽度に不安定、疲れやすい",
          },
          { value: "1", label: "1 介助を要する" },
          {
            value: "0",
            label: "0 のぼれない",
          },
        ],
      },
      {
        label: "呼吸困難",
        name: "selectValue10",
        options: [
          {
            value: "4",
            label: "4 なし",
          },
          {
            value: "3",
            label: "3 歩行中に起こる",
          },
          {
            value: "2",
            label: "2 日常動作（食事、入浴、着替え）のいずれかで起こる",
          },
          { value: "1", label: "1 坐位あるいは臥床安静時のいずれかで起こる" },
          {
            value: "0",
            label: "0 極めて困難で補助呼吸装置を考慮する",
          },
        ],
      },
      {
        label: "起坐呼吸",
        name: "selectValue11",
        options: [
          {
            value: "4",
            label: "4 なし",
          },
          { value: "3", label: "3 息切れのため夜間の睡眠がやや困難" },
          { value: "2", label: "2 眠るのに支えとする枕が多い" },
          { value: "1", label: "1 坐位でないと眠れない" },
          { value: "0", label: "0 全く眠る事ができない" },
        ],
      },
      {
        label: "呼吸不全",
        name: "selectValue12",
        options: [
          {
            value: "4",
            label: "4 なし",
          },
          {
            value: "3",
            label: "3 簡潔的に補助呼吸装置（BiPAPなど）が必要",
          },
          {
            value: "2",
            label: "2 夜間に継続的に補助呼吸装置（BiPAPなど）が必要",
          },
          {
            value: "1",
            label: "1 1日中（夜間、昼間とも）補助呼吸装置（BiPAPなど）が必要",
          },
          {
            value: "0",
            label: "0 挿管または気管切開による人工呼吸が必要",
          },
        ],
      },
    ],
    []
  );

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          ALS機能評価スケール改訂版(ALSFRS-R)
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
        <CustomTable
          rows={tableRows1}
          formData={formData}
          handleChange={handleChange}
        />
        <Box sx={{ ml: isSmallScreen ? "32%" : "40%" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isGastrostomy}
                onChange={handleChangeGastrostomy}
                inputProps={{ "aria-label": "胃瘻の設置有無" }}
              />
            }
            label={
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                {" "}
                {/* ここでフォントサイズを調整 */}
                {isGastrostomy
                  ? "(胃瘻あり)指先の動作"
                  : "(胃瘻なし)食事用具の使い方"}
              </Typography>
            }
          />
        </Box>
        <CustomTable
          rows={tableRows2}
          formData={formData}
          handleChange={handleChange}
        />

        <CustomTable
          rows={tableRows3}
          formData={formData}
          handleChange={handleChange}
        />
      </Box>
      <TotalScore formData={formData} maxScore={MAX_SCORE} />

      <Button
        variant="contained"
        color="success"
        sx={{
          position: "fixed",
          right: [10, 50, 100],
          top: [150, 150, 100],
          size: ["small", "medium", "large"],
          zIndex: 12,
        }}
        onClick={handleSaveDraft}
      >
        一時保存
      </Button>
      <ImageModal
        imageUrl={`${process.env.PUBLIC_URL}/ALSFRS.png`} // テンプレートリテラルを使用
        imageTitle="ALS機能評価スケール改訂版(ALSFRS-R)"
        buttonText="PDFを確認"
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      {pdfFilePath && (
        <PdfModal
          open={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfFilePath={pdfFilePath}
        />
      )}
      <FixedBottomNavigation editPDF={editPDF} handleRefresh={handleRefresh} />

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ALSFRS;
