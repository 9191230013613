import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  TableBody,
  Table,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";
import axios from "axios";
import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";
import CustomTable from "components/common/pdfTable/CustomTable";

const evaluationSections = {
  "1. 自覚症状": {
    a: "安静時のふるえ（四肢または顎に目立つ）がある。",
    b: "動作がのろく、ひとつの動作に時間がかかることがある。",
    c: "歩行に長時間を有し、一人でうまく歩くことができない。",
  },
  "2. 神経所見": {
    d: "毎秒４～６回のふるえが安静時に起こる（安静時振戦）。",
    e: "無動・寡動（仮面様顔貌、緩慢動作、姿勢変換の拙劣、低く単調な話し声）がある。",
    f: "歯車現象を伴うこわばり（筋固縮）がある。",
    g: "姿勢・歩行障害（前傾姿勢、突進現象、小刻み歩行、歩行時に手を振らない等）がある。",
  },
  "3. 臨床検査所見": {
    h: "一般的な検査で特異的な異常は見られない。",
    i: "脳画像（CT、MRI)に明確な異常はない。",
  },
  "4. 鑑別診断": {
    j: "脳血管障害ではない。",
    k: "薬剤疾患はない。",
    l: "その他の脳変性疾患ではない。",
  },
};

const tableRows = [
  {
    label: "Hoehn-Yahr重症度分類",
    name: "selectValue",
    options: [
      {
        value: "Ⅰ",
        label: "Ⅰ度 左右どちらか一側性の障害。振戦固縮がみられる",
      },
      {
        value: "Ⅱ",
        label: "Ⅱ度 障害が両側性である。姿勢、固縮無動などが両側にみられる",
      },
      {
        value: "Ⅲ",
        label: "Ⅲ度 明らかな歩行障害あり。姿勢反射障害あり。突進現象がみられる",
      },
      {
        value: "Ⅳ",
        label:
          "Ⅳ度 起立や歩行など日常生活動作の低下が著しい。移動や食事、清潔、排泄にかなりの障害がみられる",
      },
      {
        value: "Ⅴ",
        label:
          "Ⅴ度 完全な廃疾状態。車椅子または寝たきりの状態。全介助が必要である。",
      },
    ],
  },
  {
    label: "生活機能障害度",
    name: "selectValue2",
    options: [
      {
        value: "1",
        label: "1度 日常生活、通院に介助を必要としない",
      },
      { value: "2", label: "2度 日常生活、通院に介助を要する" },
      {
        value: "3",
        label: "3度 日常生活に全面的な介助を要し、起立歩行は不能である",
      },
    ],
  },
];
const Yahr = () => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [formData, setFormData] = useState({
    title: "パーキンソン病の重症度(Hoehn-Yahr重症度分類 & 生活機能障害度)",
    name: "",
    days: "",
    therapy: "",
    selectValue: "",
    selectValue2: "",
    // evaluationSections から動的にプロパティを生成
    ...Object.entries(evaluationSections).reduce((acc, [section, items]) => {
      Object.entries(items).forEach(([key, _]) => {
        acc[key] = false;
      });
      return acc;
    }, {}),
  });

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const handleCheckboxChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,

        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/Yahr`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName =
      "パーキンソン病の重症度(Hoehn-Yahr重症度分類 & 生活機能障害度)";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]); // 依存配列にsessionIdを指定

  const handleRefresh = () => {
    setFormData();
  };

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          パーキンソン病の重症度(Hoehn-Yahr重症度分類 & 生活機能障害度)
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
        <Typography variant="h6" gutterBottom>
          1.パーキンソン病症状チェック
        </Typography>
        <Table aria-label="evaluation table">
          <TableBody>
            {Object.entries(evaluationSections).map(
              ([section, items], sectionIndex) => (
                <TableRow key={sectionIndex}>
                  <TableCell
                    sx={{
                      color: "white",
                      backgroundColor: "#5972ff",
                      width: "30%",
                    }}
                  >
                    <Typography variant="body2">{section}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ pl: 1 }}>
                      {Object.entries(items).map(([key, item], itemIndex) => (
                        <FormControlLabel
                          key={itemIndex}
                          sx={{ mb: 2.5 }}
                          control={
                            <Checkbox
                              checked={formData[key]}
                              onChange={handleCheckboxChange}
                              name={key}
                            />
                          }
                          label={item}
                        />
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>

        <Typography variant="h6" sx={{ mt: 1.5 }} gutterBottom>
          2.パーキンソン病の重症度 (Hoehn-Yahr重症度分類 & 生活機能障害度)
        </Typography>
        <Box sx={{ mb: 5 }}>
          <CustomTable
            rows={tableRows}
            formData={formData}
            handleChange={handleChange}
          />
        </Box>
      </Box>

      <Button
        variant="contained"
        color="success"
        sx={{
          position: "fixed",
          right: [10, 50, 100],
          top: [150, 150, 100],
          size: ["small", "medium", "large"],
          zIndex: 12,
        }}
        onClick={handleSaveDraft}
      >
        一時保存
      </Button>
      <ImageModal
        imageUrl={`${process.env.PUBLIC_URL}/Yahr.png`} // テンプレートリテラルを使用
        imageTitle="Hoehn-Yahr & 生活機能障害度"
        buttonText="PDFを確認"
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      {pdfFilePath && (
        <PdfModal
          open={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfFilePath={pdfFilePath}
        />
      )}
      <FixedBottomNavigation editPDF={editPDF} handleRefresh={handleRefresh} />

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Yahr;
