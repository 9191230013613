import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";
import TextTable from "../../common/pdfTable/TextTable";
import axios from "axios";
import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";

const GMT = () => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const createInitialFormData = () => {
    const initialFormData = {
      title: "粗大筋力検査(GMT)",
      name: "",
      days: "",
      therapy: "",
    };

    for (let i = 1; i <= 19; i++) {
      initialFormData[`selectValue${i}Right`] = ""; // 右側の値
      initialFormData[`selectValue${i}Left`] = ""; // 左側の値
    }

    return initialFormData;
  };

  const [formData, setFormData] = useState(createInitialFormData());

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,
        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/GMT`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "粗大筋力検査(GMT)";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]); // 依存配列にsessionIdを指定

  const handleRefresh = () => {
    setFormData(createInitialFormData());
  };

  const commonOptions = [
    { value: "5", label: "5 N:normal" },
    { value: "4", label: "4 G:good" },
    { value: "3", label: "3 F:fair" },
    { value: "2", label: "2 P:poor" },
    { value: "1", label: "1 T:trace" },
    { value: "0", label: "0 Z:zero" },
  ];

  const tableRows = [
    {
      label: "屈曲",
      name: "selectValue1",
      options: commonOptions,
      type: "select",
      instruction: "点数は「判定基準」を参考にしてください。",
    },

    {
      label: "伸展",
      name: "selectValue2",
      options: commonOptions,
      type: "select",
    },

    {
      label: "外転",
      name: "selectValue3",
      options: commonOptions,
      type: "select",
    },
  ];
  const tableRows2 = [
    {
      label: "屈曲",
      name: "selectValue4",
      options: commonOptions,
      type: "select",
    },
    {
      label: "伸展",
      name: "selectValue5",
      options: commonOptions,
      type: "select",
    },
  ];

  const tableRows3 = [
    {
      label: "母指示指",
      name: "selectValue6",
      options: commonOptions,
      type: "select",
    },
    {
      label: "母指中指",
      name: "selectValue7",
      options: commonOptions,
      type: "select",
    },
    {
      label: "母指環指",
      name: "selectValue8",
      options: commonOptions,
      type: "select",
    },
    {
      label: "母指小指",
      name: "selectValue9",
      options: commonOptions,
      type: "select",
    },
    {
      label: "Lateral Pinch",
      name: "selectValue10",
      options: commonOptions,
      type: "select",
    },
    {
      label: "握力",
      name: "selectValue11",
      options: commonOptions,
      type: "select",
      instruction: "年齢平均と照らし合わせて判定してください。",
    },
  ];
  const tableRows4 = [
    {
      label: "屈曲",
      name: "selectValue12",
      options: commonOptions,
      type: "select",
    },
    {
      label: "伸展",
      name: "selectValue13",
      options: commonOptions,
      type: "select",
    },
    {
      label: "外転",
      name: "selectValue14",
      options: commonOptions,
      type: "select",
    },
    {
      label: "内転",
      name: "selectValue15",
      options: commonOptions,
      type: "select",
    },
  ];
  const tableRows5 = [
    {
      label: "屈曲",
      name: "selectValue16",
      options: commonOptions,
      type: "select",
    },
    {
      label: "伸展",
      name: "selectValue17",
      options: commonOptions,
      type: "select",
    },
  ];
  const tableRows6 = [
    {
      label: "屈曲",
      name: "selectValue18",
      options: commonOptions,
      type: "select",
    },
    {
      label: "伸展",
      name: "selectValue19",
      options: commonOptions,
      type: "select",
    },
  ];

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          粗大筋力検査 (GMT)
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
        <Typography variant="h6" gutterBottom>
          肩関節
        </Typography>
        <TextTable
          rows={tableRows}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          肘関節
        </Typography>
        <TextTable
          rows={tableRows2}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          対立
        </Typography>
        <TextTable
          rows={tableRows3}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          股関節
        </Typography>
        <TextTable
          rows={tableRows4}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          膝関節
        </Typography>
        <TextTable
          rows={tableRows5}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          足関節
        </Typography>
        <TextTable
          rows={tableRows6}
          formData={formData}
          handleChange={handleChange}
        />
      </Box>

      <Button
        variant="contained"
        color="success"
        sx={{
          position: "fixed",
          right: [10, 50, 100],
          top: [150, 150, 100],
          size: ["small", "medium", "large"],
          zIndex: 12,
        }}
        onClick={handleSaveDraft}
      >
        一時保存
      </Button>

      <ImageModal
        imageUrl={`${process.env.PUBLIC_URL}/GMT2.png`} // テンプレートリテラルを使用
        imageTitle="粗大筋力検査の判定基準"
        buttonText="判定基準"
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      {pdfFilePath && (
        <PdfModal
          open={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfFilePath={pdfFilePath}
        />
      )}
      <FixedBottomNavigation editPDF={editPDF} handleRefresh={handleRefresh} />

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default GMT;
