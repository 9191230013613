import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";
import CustomTable from "components/common/pdfTable/CustomTable";
import axios from "axios";
import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";
import TotalScore from "../../navigation/TotalScore";

const MAX_SCORE = 30;

const MNA = () => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const createInitialFormData = () => {
    const initialFormData = {
      title: "簡易栄養状態評価表(MNA)",
      name: "",
      days: "",
      therapy: "",
    };

    for (let i = 1; i <= 14; i++) {
      initialFormData[`selectValue${i}`] = "";
    }

    return initialFormData;
  };

  const [formData, setFormData] = useState(createInitialFormData());

  const calculateTotalScore = () => {
    let totalScore = 0;
    for (let i = 1; i <= 18; i++) {
      // formDataの各selectValueが空文字列の場合は0を使用する
      totalScore += Number(formData[`selectValue${i}`]) || 0;
    }
    return totalScore;
  };

  const calculateScoreRange = (start, end) => {
    let total = 0;
    for (let i = start; i <= end; i++) {
      total += Number(formData[`selectValue${i}`]) || 0;
    }
    return total;
  };


  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");
      const scoreRange1 = calculateScoreRange(1, 6);
      const scoreRange2 = calculateScoreRange(7, 18);
      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,
        scoreRange1: scoreRange1.toString(), // 新しい範囲のスコア
        scoreRange2: scoreRange2.toString(),
        totalScore: calculateTotalScore().toString(),
        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/MNA`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "Berg Balance Scale(BBS)";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]);

  const handleRefresh = () => {
    setFormData(createInitialFormData());
  };

  const tableRows = [
    {
      label: "A",
      name: "selectValue1",
      options: [
        {
          value: "0",
          label: "0 著しい食事量の減少",
        },
        { value: "1", label: "1 中東どの食事量の減少" },
        { value: "2", label: "2 食事量の減少なし" },
      ],
      instruction: "過去3ヶ月間で食欲不振、消化器系の問題、咀嚼・嚥下困難などで食事量が減少しましたか？",
    },
    {
      label: "B",
      name: "selectValue2",
      options: [
        {
          value: "0",
          label: "0 3kg以上の減少",
        },
        { value: "1", label: "1 わからない" },
        { value: "2", label: "2 1〜3kg の減少" },
        { value: "3", label: "3 体重減少なし" },
      ],
      instruction:"過去3ヶ月間で体重の減少がありましたか？",
    },
    {
      label: "C",
      name: "selectValue3",
      options: [
        {
          value: "0",
          label: "0 寝たきりまたは車椅子を常時使用",
        },
        { value: "1", label: "1 ベッドや車椅子を離れらるが、歩いて外出できない" },
        { value: "2", label: "2 自由に歩いて外出できない" },
      ],
      instruction:"自力で歩けますか？",
    },
    {
      label: "D",
      name: "selectValue4",
      options: [
        {
          value: "0",
          label: "0 はい",
        },
        { value: "2", label: "2 いいえ" },
      ],
      instruction: "過去3ヶ月間で精神的ストレスや急性疾患を経験しましたか？",
    },
    {
      label: "E",
      name: "selectValue5",
      options: [
        {
          value: "0",
          label: "0 強度認知症またはうつ状態",
        },
        { value: "1", label: "1 中等度の認知症" },
        { value: "2", label: "2 精神的問題なし" },
      ],
      instruction: "神経・精神的問題の有無",
    },
    {
      label: "F",
      name: "selectValue6",
      options: [
        {
          value: "0",
          label: "0 BMIが19未満",
        },
        { value: "1", label: "1 BMIが19以上、21未満" },
        { value: "2", label: "2 BMIが21以上、23未満" },
        {
          value: "3",
          label: "3 BMIが23以上",
        },
      ],
      instruction: "BMI体重(kg) / [身長(m)]2",
    },
  ]

  const tableRows1 =[
    {
      label: "G ",
      name: "selectValue7",
      options: [
        {
          value: "0",
          label: "0 いいえ",
        },
        {
          value: "1",
          label: "1 はい",
        },
       
      ],
      instruction: "生活は自立していましか？(施設入所や入院をしていない)",
    },
    {
      label: "H",
      name: "selectValue8",
      options: [
        {
          value: "0",
          label: "0 いいえ",
        },
        {
          value: "1",
          label: "1 はい",
        },
      ],
      instruction:
        "1日に4種類以上の処方薬を飲んでいる",
    },
    {
      label: "I",
      name: "selectValue9",
      options: [
        {
          value: "0",
          label: "0 いいえ",
        },
        {
          value: "1",
          label: "1 はい",
        },
      ],
      instruction: "身体のどこかに押して痛いところ、または皮膚潰瘍がある",
    },
    {
      label: "J",
      name: "selectValue10",
      options: [
        {
          value: "0",
          label: "0 1回",
        },
        {
          value: "1",
          label:
            "1 2回",
        },
        { value: "2", label: "2 3回" },
       
      ],
      instruction:
        "1日に何回食事を摂っていますか？",
    },
    {
      label: "K",
      name: "selectValue11",
      options: [
        {
          value: "0",
          label: "0 はい、0〜1つ",
        },
        { value: "0.5", label: "0.5 はい、2つ" },
        { value: "1", label: "1 はい、3つ" },
       
      ],
      instruction:
        "どんなタンパク質を、どの程度摂っていますか？・乳製品(牛乳・チーズ・ヨーグルト)を毎日1品以上摂取・豆類または卵を毎週2品以上摂取・肉類または魚を毎日摂取",
        
    },
    {
      label: "L",
      name: "selectValue12",
      options: [
        {
          value: "0",
          label: "0 いいえ",
        },
        {
          value: "1",
          label: "1 はい",
        },
      
      ],
      instruction: "果物または野菜を毎日2品以上摂っていますか？",
    },
    {
      label: "M",
      name: "selectValue13",
      options: [
        {
          value: "0",
          label: "0 コップ3杯未満",
        },
        { value: "0.5", label: "0.5 3杯以上5杯未満" },
        { value: "1", label: "1 5杯以上" },
       
      ],
      instruction: "水分(水、ジュース、コーヒー、茶、牛乳など)を1日どのくらい摂っていますか？",
    },
    {
      label: "N",
      name: "selectValue14",
      options: [
        {
          value: "0",
          label: "0 介護なしで食事不可能",
        },
        { value: "1", label: "1 多少困難ではあるが自力で食事可能" },
        { value: "2", label: "2 問題なく自力で食事可能" },
       
      ],
      instruction: "食事状況",
    },
    {
      label: "O",
      name: "selectValue15",
      options: [
        {
          value: "0",
          label: "0 自分で低栄養だと思う",
        },
        { value: "1", label: "1 わからない" },
        { value: "2", label: "2 問題ないと思う" },
       
      ],
      instruction: "栄養状態の自己評価",
    },
    {
      label: "P",
      name: "selectValue16",
      options: [
        {
          value: "0",
          label: "0 良くない",
        },
        { value: "0.5", label: "0.5 わからない" },
        { value: "1", label: "1 同じ" },
        { value: "2", label: "2 良い" },
       
      ],
      instruction: "同年齢の人と比べて、自分の健康状態をどう思いますか？",
    },
    {
      label: "Q",
      name: "selectValue17",
      options: [
        {
          value: "0",
          label: "0 21cm未満",
        },
        { value: "0.5", label: "0.5 21cm以上、22cm未満" },
        { value: "1", label: "1 22cm以上" },
       
      ],
      instruction: "上腕(利き腕ではない方)の中央の周囲長(cm):MAC",
    },
    {
      label: "R",
      name: "selectValue18",
      options: [
        {
          value: "0",
          label: "0 31cm未満",
        },
        { value: "1", label: "1 31cm以上" },
       
      ],
      instruction: "ふくらはぎの周囲長(cm):CC",
    },
   
  ];

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
        簡易栄養状態評価表(MNA)
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
           <Typography variant="h6" >
          スクリーニング
        </Typography>
           <Typography variant="caption">
          スクリーニングが11ポイント以下の場合、次のアセスメントに進んでください。
        </Typography>
        <CustomTable
          rows={tableRows}
          formData={formData}
          handleChange={handleChange}
        />
         <Typography variant="h6" gutterBottom >
          アセスメント
        </Typography>
        <CustomTable
          rows={tableRows1}
          formData={formData}
          handleChange={handleChange}
        />
      </Box>
      <TotalScore formData={formData} maxScore={MAX_SCORE} />
      <Button
        variant="contained"
        color="success"
        sx={{
          position: "fixed",
          right: [10, 50, 100],
          top: [150, 150, 100],
          size: ["small", "medium", "large"],
          zIndex: 12,
        }}
        onClick={handleSaveDraft}
      >
        一時保存
      </Button>
      <ImageModal
        imageUrl={`${process.env.PUBLIC_URL}/BBS.png`} // テンプレートリテラルを使用
        imageTitle="Berg Balance Scale(BBS)"
        buttonText="PDFを確認"
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      {pdfFilePath && (
        <PdfModal
          open={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfFilePath={pdfFilePath}
        />
      )}
      <FixedBottomNavigation editPDF={editPDF} handleRefresh={handleRefresh} />

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MNA;
