import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  TableCell,
  TableRow,
  TableBody,
  Table,
  FormControl,
  Container,
  Button,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop,
} from "@mui/material";

import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";
import TotalScore from "../../navigation/TotalScore";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";

const MAX_SCORE = 126;

export default function BathelIndex() {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const [formData, setFormData] = React.useState({
    title: "FIM",
    name: "",
    days: "",
    therapy: "",
    selectValue: "",
    selectValue2: "",
    selectValue3: "",
    selectValue4: "",
    selectValue5: "",
    selectValue6: "",
    selectValue7: "",
    selectValue8: "",
    selectValue9: "",
    selectValue10: "",
    selectValue11: "",
    selectValue12: "",
    selectValue13: "",
    selectValue14: "",
    selectValue15: "",
    selectValue16: "",
    selectValue17: "",
    selectValue18: "",
    selectValue19: "",
  });

  const initialFormData = {
    title: "FIM",
    name: "",
    days: "",
    therapy: "",

    selectValue: "",
    selectValue2: "",
    selectValue3: "",
    selectValue4: "",
    selectValue5: "",
    selectValue6: "",
    selectValue7: "",
    selectValue8: "",
    selectValue9: "",
    selectValue10: "",
    selectValue11: "",
    selectValue12: "",
    selectValue13: "",
    selectValue14: "",
    selectValue15: "",
    selectValue16: "",
    selectValue17: "",
    selectValue18: "",
    selectValue19: "",
  };
  const calculateTotalScore = () => {
    return (
      +formData.selectValue +
      +formData.selectValue2 +
      +formData.selectValue3 +
      +formData.selectValue4 +
      +formData.selectValue5 +
      +formData.selectValue6 +
      +formData.selectValue7 +
      +formData.selectValue8 +
      +formData.selectValue9 +
      +formData.selectValue10 +
      +formData.selectValue11 +
      +formData.selectValue12 +
      +formData.selectValue13 +
      +formData.selectValue14 +
      +formData.selectValue15 +
      +formData.selectValue16 +
      +formData.selectValue17 +
      +formData.selectValue18 +
      +formData.selectValue19
    );
  };

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,
        totalScore: calculateTotalScore().toString(),
        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/FIM`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "FIM";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]); // 依存配列にsessionIdを指定

  const handleRefresh = () => {
    setFormData(initialFormData);
  };

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          FIM
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <Box sx={{ paddingLeft: "5px", paddingBottom: "2px" }}></Box>
        <Typography variant="h6" gutterBottom>
          運動項目
        </Typography>
        <Typography variant="body1" gutterBottom>
          セルフケア
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "one",
                  label: "食事",
                  instruction:
                    "採点:\n口に運ぶ・かき集める・飲み込むに分けて採点する。",
                },
                {
                  id: "two",
                  label: "整容",
                  instruction:
                    "採点:\n口腔内の清潔・整髪・手洗い・洗顔・髭剃りに分けて採点する。",
                },
                {
                  id: "three",
                  label: "清拭",
                  instruction:
                    "採点:\n胸部・両上肢・腹部・両大腿部・両下腿部・会陰部前面・臀部で何ヶ所を自分で行えて、何ヶ所を介助しているかを評価する。例:（3/10=30% 2点）",
                },
                {
                  id: "four",
                  label: "更衣・上半身",
                  instruction:
                    "採点:\n動作を分解して何動作できているかで採点する。",
                },
                {
                  id: "five",
                  label: "更衣・下半身",
                  instruction:
                    "採点:\n下衣の種類を分解して採点する。（ズボン・下着・靴下・靴）",
                },

                {
                  id: "six",
                  label: "トイレ動作",
                  instruction:
                    "採点:\nズボンを下げる・ズボンを上げる・お尻を拭くに分けて採点する。",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "25%",
                      color: "white",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "one" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue"
                                  value={formData.selectValue}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      すべての性状の食物を皿から口まで運び、咀嚼して嚥下できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      時間がかかる．自助具を使用する．部分的に非経口的栄養に頼る。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備 自助具の装着をしてもらう。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助 食事動作の75%以上を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      食事動作の50%以上75%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      食事動作の25%以上50%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助 食事動作の25%未満しか行えない。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "two" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue2"
                                  value={formData.selectValue2}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      歯磨き・手洗い・洗顔・髭剃り・化粧を全て自力で行っている。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      時間がかかる。自助具を使用している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備 監視が必要、準備が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助 整容動作の75%以上を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      整容動作の50%以上75%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      整容動作の25%以上50%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助 整容動作の25%未満しか行えない。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "three" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  value={formData.selectValue3}
                                  name="selectValue3"
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      身体を洗い、乾かすことができる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      時間がかかる。自助具を使用している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      介助や監視や準備が必要になる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助 食事動作の75%以上を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      食事動作の50%以上75%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      食事動作の25%以上50%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助 食事動作の25%未満しか行えない。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "four" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue4"
                                  value={formData.selectValue4}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      衣服を取り出すことを含めた衣服の着脱が自立している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      時間がかかる、自助具を使用している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      介助や監視や準備が必要になる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助 食事動作の75%以上を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      食事動作の50%以上75%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      食事動作の25%以上50%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助 食事動作の25%未満しか行えない。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "five" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue5"
                                  value={formData.selectValue5}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      自力でたんすから衣服を取り出し、腰から下衣・装具の着脱をしている。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      時間がかかる、改良した衣服や自助具を使用している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      介助や監視や準備が必要になる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助 食事動作の75%以上を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      食事動作の50%以上75%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      食事動作の25%以上50%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助 食事動作の25%未満しか行えない。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "six" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue6"
                                  value={formData.selectValue6}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      自力で衣服を下ろし、排泄後会陰部を清潔にし、衣服を再び挙げている
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      時間がかかる、手すりや自助具を使用している
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      介助や監視や準備が必要になる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助 食事動作の75%以上を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      食事動作の50%以上75%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      食事動作の25%以上50%未満を行う。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助 食事動作の25%未満しか行えない。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid sx={{ marginTop: "8px" }}>
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          {row.instruction
                            .split("\n")
                            .map((str, index, array) => (
                              <span key={index}>
                                {str}
                                {index !== array.length - 1 && <br />}
                              </span>
                            ))}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ paddingLeft: "5px", paddingBottom: "2px" }}></Box>

        <Typography variant="body1" gutterBottom>
          排泄コントロール
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "seven",
                  label: "排尿コントロール",
                  instruction:
                    "採点:\n失敗の頻度と介助量を採点し低い方を採用する。",
                },
                {
                  id: "eight",
                  label: "排便コントロール",
                  instruction:
                    "採点:\n排便をしてもよい状況で、タイミングよく括約筋を緩めるというところを採点する。",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "25%",
                      color: "white",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "seven" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue7"
                                  value={formData.selectValue7}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      失敗もなく、準備も含め自立でタイミングよく排尿可能である。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      時間がかかる。投薬している。補助具を使用している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備 監視が必要、準備が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>4 最小介助 75%以上を行う。</em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>3 中等度介助 50%以上75%未満を行う。</em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>2 最大介助 25%以上50%未満を行う。</em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>1 全介助 25%未満しか行えない。</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "eight" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue8"
                                  value={formData.selectValue8}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      失敗なく、準備も含め自力でタイミングよく排便することができる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      時間がかかる。投薬している。補助具を使用している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備 監視が必要、準備が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>4 最小介助 75%以上を行う。</em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>3 中等度介助 50%以上75%未満を行う。</em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>2 最大介助 5%以上50%未満を行う。</em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>1 全介助 25%未満しか行えない。</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid sx={{ marginTop: "8px" }}>
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          {row.instruction
                            .split("\n")
                            .map((str, index, array) => (
                              <span key={index}>
                                {str}
                                {index !== array.length - 1 && <br />}
                              </span>
                            ))}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ paddingLeft: "5px", paddingBottom: "2px" }}></Box>

        <Typography variant="body1" gutterBottom>
          移乗
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "nine",
                  label: "ベッド・椅子・車椅子",
                  instruction: "採点:\n往復の動作も含めて採点する。",
                },
                {
                  id: "ten",
                  label: "トイレ",
                  instruction:
                    "採点:\n便器の移ること、および便器から離れることを評価する。",
                },
                {
                  id: "eleven",
                  label: "浴槽シャワー",
                  instruction:
                    "採点:\n浴槽またはシャワー室に入り、そこから出る動作を評価する。",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "25%",
                      color: "white",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "nine" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue9"
                                  value={formData.selectValue9}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      装具や手すりが不要で自力で移乗している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>6 修正自立 手すりなどが必要になる。</em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備 監視が必要、準備が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>4 最小介助 触れる程度の介助が必要。</em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助 軽く引き上げたりが必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      しっかり引き上げる、回すことが必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>1 全介助 全介助・2人介助が必要。</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "ten" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue10"
                                  value={formData.selectValue10}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      装具や手すりが不要で自力で移乗している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>6 修正自立 手すり等が必要になる。</em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備 監視が必要、準備が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>4 最小介助 触れる程度の介助が必要。</em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助 軽く引き上げたりが必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      しっかり引き上げる、回すことが必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>1 全介助 全介助・2人介助が必要。</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "eleven" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  value={formData.selectValue11}
                                  name="selectValue11"
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      装具や手すりが不要で自力で移乗している。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>6 修正自立 手すり等が必要になる。</em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備 監視が必要、準備が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助 足を片側跨がせる介助が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助 両足跨がせる介助が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      しっかり引き上げる、回すことが必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助 2人必要になる、機械浴を利用する。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid sx={{ marginTop: "8px" }}>
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          {row.instruction
                            .split("\n")
                            .map((str, index, array) => (
                              <span key={index}>
                                {str}
                                {index !== array.length - 1 && <br />}
                              </span>
                            ))}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ paddingLeft: "5px", paddingBottom: "2px" }}></Box>

        <Typography variant="body1" gutterBottom>
          移動
        </Typography>
        <Typography variant="body2" gutterBottom color="primary">
          主な移動手段を選択し採点する。
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "twelve",
                  label: "歩行",
                  instruction:
                    "採点:\n50m移動可能で3点以上、不可能で2点以下で採点する。",
                },
                {
                  id: "thirteen",
                  label: "車椅子",
                  instruction:
                    "採点:\n50m移動可能で3点以上、不可能で2点以下で採点する。",
                },
                {
                  id: "fourteen",
                  label: "階段",
                  instruction:
                    "採点:\n屋内12~14段で評価する。昇り、降りで差がある場合は低い方で採点する。",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "25%",
                      color: "white",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "twelve" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue12"
                                  value={formData.selectValue12}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立 介助なしで50m可能である。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      介助ないが補助具で50m可能である。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備 監視が必要、準備が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>4 最小介助 介助量が25%以下である。</em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>3 中等度介助 介助量が25%以上である</em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      15m介助が必要、介助量が75%以下である。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助
                                      15m介助が必要、介助量が75%以上である。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "thirteen" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue13"
                                  value={formData.selectValue13}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立 介助なしで50m可能である。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      介助ないが補助具で50m可能である。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備 監視が必要、準備が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>4 最小介助 介助量が25%以下である。</em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>3 中等度介助 介助量が25%以上である</em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      15m介助が必要、介助量が75%以下である。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助
                                      15m介助が必要、介助量が75%以上である。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "fourteen" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  value={formData.selectValue14}
                                  name="selectValue14"
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立 介助なしで12~14段可能である。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      手すり等利用で12~14段可能である。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      介助や監視や準備が必要になる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助 触れる程度介助が必要になる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      足の運びの介助、からだの引き上げ介助が必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      12~14段は不能だが触れる程度で可能。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>1 全介助 2人がかり・利用しない。</em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid sx={{ marginTop: "8px" }}>
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          {row.instruction
                            .split("\n")
                            .map((str, index, array) => (
                              <span key={index}>
                                {str}
                                {index !== array.length - 1 && <br />}
                              </span>
                            ))}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ paddingLeft: "5px", paddingBottom: "2px" }}></Box>
        <Typography variant="h6" gutterBottom>
          認知項目
        </Typography>
        <Typography variant="body1" gutterBottom>
          コミュニケーション
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "fifteen",
                  label: "理解",
                  instruction:
                    "採点:\n言葉を聞き分けるまでを評価する。その後の判断は採点外。",
                },
                {
                  id: "sixteen",
                  label: "表出",
                  instruction:
                    "採点:\n言おうとする内容が、相手に伝わるかどうかを評価する。\n質問と違う内容でも流暢で聞き取れれば評価される。",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "25%",
                      color: "white",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "fifteen" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue15"
                                  value={formData.selectValue15}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      基本的欲求・複雑な内容も理解できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      複雑な内容の理解に時間がかかる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      基本的欲求の理解は可能、複雑な内容は困難。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助
                                      基本的欲求の理解がほぼ可能だが促しが必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      基本的欲求の理解に協調語を用いた短い句で話す必要がある。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      基本的欲求の理解に単語・ジェスチャー・yes,noを用いて可能。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助 基本的欲求の理解に介助が必要。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "sixteen" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue16"
                                  value={formData.selectValue16}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      基本的欲求・複雑な内容も表出できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      複雑な内容の表出に時間がかかる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      基本的欲求の表出は可能、複雑な内容は困難。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助
                                      基本的欲求の表出はほぼ可能だが促しが必要。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      基本的欲求の表出に協調語を用いた短い句で話す必要がある。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      基本的欲求の表出に単語・ジェスチャー・yes,noを用いて可能。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助
                                      基本的欲求の表出に理解に介助が必要。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid sx={{ marginTop: "8px" }}>
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          {row.instruction
                            .split("\n")
                            .map((str, index, array) => (
                              <span key={index}>
                                {str}
                                {index !== array.length - 1 && <br />}
                              </span>
                            ))}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ paddingLeft: "5px", paddingBottom: "2px" }}></Box>

        <Typography variant="body1" gutterBottom>
          社会的認知
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "seventeen",
                  label: "社会的交流",
                  instruction:
                    "採点:\n相手に迷惑をかけているかどうか、言動が人にどのように思われているかを採点する。",
                },
                {
                  id: "eighteen",
                  label: "問題解決",
                  instruction:
                    "採点:\n日常生活の中で起こる問題に対応できているかを採点する。",
                },
                {
                  id: "nineteen",
                  label: "記憶",
                  instruction:
                    "採点:\n日常生活を行う上で必要な内容を覚えていられるかで採点する。\n「頻繁に出会う人」「毎日の日課」「他人からの依頼」の3つの課題を覚えているかで採点する。",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "25%",
                      color: "white",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "seventeen" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue17"
                                  value={formData.selectValue17}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      不慣れな環境でも問題なく関わっている。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      不慣れな環境に慣れるのに時間がかかる。投薬であれば問題ない。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      不慣れな環境に監視・指示・促しがあれば可能。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助
                                      慣れた環境でも75%以上の場面で適切に交流できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      慣れた環境でも50%~75%未満の場面で適切に交流できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      慣れた環境でも25%~50%未満の場面で適切に交流できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助
                                      慣れた環境でも25%未満の場面でしか交流できない。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "eighteen" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  name="selectValue18"
                                  value={formData.selectValue18}
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      複雑な問題に対して内容を認識して、決断し、実行できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      複雑な課題に対して時間がかかるが認識して、決断し、実行できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      複雑な課題に対して必ずしも決断し、実行できるとは言えない
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助
                                      日常の課題に対して行動しない場合が「10〜25%未満」
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      日常の課題に対して行動しない場合が「25〜50%未満」
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      日常の課題に対して行動しない場合が「50〜75%未満」
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助
                                      日常の課題に対して行動しない場合が「75%以上」
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "nineteen" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 150 }}
                              >
                                <InputLabel id="select-value-label">
                                  点数
                                </InputLabel>
                                <Select
                                  labelId="select-value-label"
                                  id="select-value"
                                  value={formData.selectValue19}
                                  name="selectValue19"
                                  onChange={handleChange}
                                  label="Value"
                                >
                                  <MenuItem value="7">
                                    <em>
                                      7 完全自立
                                      3つの課題を他人の配慮なしに記憶、再生できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="6">
                                    <em>
                                      6 修正自立
                                      3つの課題を記憶しているが、通常以上の時間がかかる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="5">
                                    <em>
                                      5 監視・準備
                                      3つの課題を手帳に記載、管理のために他者が補助すれば記憶、再生できる。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="4">
                                    <em>
                                      4 最小介助
                                      3つの課題が「10〜25%未満」で記憶・再生できない。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="3">
                                    <em>
                                      3 中等度介助
                                      3つの課題が「25〜50%未満」で記憶・再生できない。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="2">
                                    <em>
                                      2 最大介助
                                      3つの課題が「50〜75%未満」で記憶・再生できない。
                                    </em>
                                  </MenuItem>
                                  <MenuItem value="1">
                                    <em>
                                      1 全介助
                                      3つの課題が「75%以上」または「全く」記憶・再生できない。
                                    </em>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid sx={{ marginTop: "8px" }}>
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          {row.instruction
                            .split("\n")
                            .map((str, index, array) => (
                              <span key={index}>
                                {str}
                                {index !== array.length - 1 && <br />}
                              </span>
                            ))}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <TotalScore formData={formData} maxScore={MAX_SCORE} />
      <Button
        variant="contained"
        color="success"
        sx={{
          position: "fixed",
          right: [10, 50, 100],
          top: [150, 150, 100],
          size: ["small", "medium", "large"],
          zIndex: 12,
        }}
        onClick={handleSaveDraft}
      >
        一時保存
      </Button>
      <ImageModal
        imageUrl={`${process.env.PUBLIC_URL}/FIM.png`} // テンプレートリテラルを使用
        imageTitle="FIM採点基準"
        buttonText="採点基準を確認"
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      {pdfFilePath && (
        <PdfModal
          open={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfFilePath={pdfFilePath}
        />
      )}
      <FixedBottomNavigation editPDF={editPDF} handleRefresh={handleRefresh} />

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
