import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";
import CustomTable from "components/common/pdfTable/CustomTable";
import axios from "axios";
import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageModal from "../../navigation/ImageModal";
import TotalScore from "../../navigation/TotalScore";

const MAX_SCORE = 20;

const GUSS = () => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const createInitialFormData = () => {
    const initialFormData = {
      title: "Gugging Swallowing Screen(GUSS)",
      name: "",
      days: "",
      therapy: "",
    };

    for (let i = 1; i <= 17; i++) {
      initialFormData[`selectValue${i}`] = "";
    }

    return initialFormData;
  };

  const [formData, setFormData] = useState(createInitialFormData());

  const calculateTotalScore = () => {
    let totalScore = 0;
    for (let i = 1; i <= 17; i++) {
      // formDataの各selectValueが空文字列の場合は0を使用する
      totalScore += Number(formData[`selectValue${i}`]) || 0;
    }
    return totalScore;
  };

  const calculateScoreRange = (start, end) => {
    let total = 0;
    for (let i = start; i <= end; i++) {
      total += Number(formData[`selectValue${i}`]) || 0;
    }
    return total;
  };

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      // 新しい範囲ごとのトータルスコアを計算
      const scoreRange1 = calculateScoreRange(1, 5);
      const scoreRange2 = calculateScoreRange(6, 9);
      const scoreRange3 = calculateScoreRange(10, 13);
      const scoreRange4 = calculateScoreRange(14, 17);
      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,
        totalScore: calculateTotalScore().toString(),
        scoreRange1: scoreRange1.toString(), // 新しい範囲のスコア
        scoreRange2: scoreRange2.toString(),
        scoreRange3: scoreRange3.toString(),
        scoreRange4: scoreRange4.toString(),
        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/GUSS`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "Gugging Swallowing Screen(GUSS)";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]); // 依存配列にsessionIdを指定

  const handleRefresh = () => {
    setFormData(createInitialFormData());
  };

  const tableRows = [
    {
      label: "覚醒状態:15分以上",
      name: "selectValue1",
      options: [
        {
          value: "1",
          label: "1 Yes",
        },
        { value: "0", label: "0 No" },
      ],
    },
    {
      label: "咳 and/or 咳払い",
      name: "selectValue2",
      options: [
        {
          value: "1",
          label: "1 Yes",
        },
        { value: "0", label: "0 No" },
      ],
    },
    {
      label: "唾液嚥下:成功",
      name: "selectValue3",
      options: [
        {
          value: "1",
          label: "1 Yes",
        },
        { value: "0", label: "0 No" },
      ],
    },
    {
      label: "：流涎",
      name: "selectValue4",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
    {
      label: "：声の変化",
      name: "selectValue5",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
  ];

  const tableRows2 = [
    {
      label: "嚥下運動",
      name: "selectValue6",
      options: [
        {
          value: "0",
          label: "0 嚥下不能",
        },
        { value: "1", label: "1 嚥下遅延(2秒以上,固体は10秒以上)" },
        { value: "2", label: "2 嚥下成功" },
      ],
    },
    {
      label: "むせ(嚥下前,嚥下中,嚥下後3分以内)",
      name: "selectValue7",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
    {
      label: "流涎",
      name: "selectValue8",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
    {
      label: "声の変化(嚥下前後の声の観察,オーと言わせる)",
      name: "selectValue9",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
  ];
  const tableRows3 = [
    {
      label: "嚥下運動",
      name: "selectValue10",
      options: [
        {
          value: "0",
          label: "0 嚥下不能",
        },
        { value: "1", label: "1 嚥下遅延(2秒以上,固体は10秒以上)" },
        { value: "2", label: "2 嚥下成功" },
      ],
    },
    {
      label: "むせ(嚥下前,嚥下中,嚥下後3分以内)",
      name: "selectValue11",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
    {
      label: "流涎",
      name: "selectValue12",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
    {
      label: "声の変化(嚥下前後の声の観察,オーと言わせる)",
      name: "selectValue13",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
  ];
  const tableRows4 = [
    {
      label: "嚥下運動",
      name: "selectValue14",
      options: [
        {
          value: "0",
          label: "0 嚥下不能",
        },
        { value: "1", label: "1 嚥下遅延(2秒以上,固体は10秒以上)" },
        { value: "2", label: "2 嚥下成功" },
      ],
    },
    {
      label: "むせ(嚥下前,嚥下中,嚥下後3分以内)",
      name: "selectValue15",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
    {
      label: "流涎",
      name: "selectValue16",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
    {
      label: "声の変化(嚥下前後の声の観察,オーと言わせる)",
      name: "selectValue17",
      options: [
        {
          value: "0",
          label: "0 Yes",
        },
        { value: "1", label: "1 No" },
      ],
    },
  ];

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Gugging Swallowing Screen(GUSS)
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
        <Typography variant="h5" gutterBottom>
          1.予備検査/間接嚥下テスト
        </Typography>
        <CustomTable
          rows={tableRows}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h5" sx={{ mt: 2 }} gutterBottom>
          1.直接嚥下テスト
        </Typography>
        <Typography variant="h6" gutterBottom>
          半固形
        </Typography>
        <CustomTable
          rows={tableRows2}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          液体
        </Typography>
        <CustomTable
          rows={tableRows3}
          formData={formData}
          handleChange={handleChange}
        />
        <Typography variant="h6" gutterBottom>
          固形
        </Typography>
        <CustomTable
          rows={tableRows4}
          formData={formData}
          handleChange={handleChange}
        />
      </Box>
      <TotalScore formData={formData} maxScore={MAX_SCORE} />
      <Button
        variant="contained"
        color="success"
        sx={{
          position: "fixed",
          right: [10, 50, 100],
          top: [150, 150, 100],
          size: ["small", "medium", "large"],
          zIndex: 12,
        }}
        onClick={handleSaveDraft}
      >
        一時保存
      </Button>
      <ImageModal
        imageUrl={`${process.env.PUBLIC_URL}/GUSS.png`} // テンプレートリテラルを使用
        imageTitle="GUSS評価表"
        buttonText="GUSS評価表"
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      {pdfFilePath && (
        <PdfModal
          open={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfFilePath={pdfFilePath}
        />
      )}
      <FixedBottomNavigation editPDF={editPDF} handleRefresh={handleRefresh} />

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default GUSS;
