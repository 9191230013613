import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  CircularProgress,
  Divider,
  Pagination,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Link,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SearchIcon from "@mui/icons-material/Search";
//import TutorialModal from "../components/navigation/TutorialModal";



function UserHome() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("relevant"); // 初期並べ替えオプション
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // 総ページ数
  const [searchType, setSearchType] = React.useState("keyword"); // デフォルトは

  const count = 20;

  // 検索関数を更新
  const search = async (page = currentPage) => {
    setLoading(true);
    const offset = (page - 1) * count; // ページあたりのオフセット
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/search?q=${encodeURIComponent(
        query
      )}&count=${count}&start=${offset}&sort=${sortOrder}&searchType=${searchType}`
    );
    const data = await response.json();
    setTotalPages(Math.ceil(data["opensearch:totalResults"] / count)); // 総ページ数を計算
    setResults(data["items"] || []);
    setLoading(false);
  };

  // ページ変更時のハンドラ
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    search(value); // 選択されたページで検索
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    search(currentPage); // 新しい並べ替えオプションで検索を再実行
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 950,minWidth:300, margin: "auto", mt: 1 }}>
        <Paper
          style={{ padding: "1.5rem", marginBottom: "1rem" }}
          elevation={3}
        >
         <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
        論文検索
      </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 1,
            }}
          >
            <TextField
              label="キーワードで検索"
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{ width: "100%", mr: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={search}
              disabled={loading}
              sx={{
                padding: "10px 15px",
                minWidth: "90px",
                whiteSpace: "nowrap",
                height: "56px",
                size: ["small", "medium", "large"],
              }}
            >
              {loading ? <CircularProgress size={24} /> : <SearchIcon />}
            </Button>
          </Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>検索オプション</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <FormControl
                  variant="outlined"
                  sx={{
                    marginRight: { sm: 2 },
                    marginBottom: { xs: 2, sm: 0 },
                    width: "100%",
                  }}
                >
                  <InputLabel id="sort-order-label">並べ替え</InputLabel>
                  <Select
                    labelId="sort-order-label"
                    id="sort-order-select"
                    value={sortOrder}
                    onChange={handleSortChange}
                    label="並べ替え"
                    fullWidth
                  >
                    <MenuItem value="relevant">関連度順</MenuItem>
                    <MenuItem value="newest">新しい順</MenuItem>
                    <MenuItem value="oldest">古い順</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  component="fieldset"
                  sx={{ width: "100%", marginBottom: 2 }}
                >
                  <FormLabel component="legend">検索タイプ</FormLabel>

                  <RadioGroup
                    row
                    aria-label="search type"
                    name="searchType"
                    value={searchType}
                    onChange={handleSearchTypeChange}
                  >
                    <FormControlLabel
                      value="keyword"
                      control={
                        <Radio
                          sx={{
                            color: "primary.main", // デフォルトカラー
                            "&.Mui-checked": {
                              color: "primary.main", // チェックされたときのカラー
                            },
                            "& .MuiSvgIcon-root": {
                              // アイコンサイズをカスタマイズする
                              fontSize: "10", // サイズ調整
                            },
                          }}
                        />
                      }
                      label="フリーワード"
                      sx={{
                        ".MuiFormControlLabel-label": {
                          // ラベルのスタイリング
                          fontSize: "0.875rem", // フォントサイズの調整
                        },
                      }}
                    />
                    <FormControlLabel
                      value="title"
                      control={
                        <Radio
                          sx={{
                            color: "primary.main", // デフォルトカラー
                            "&.Mui-checked": {
                              color: "primary.main", // チェックされたときのカラー
                            },
                            "& .MuiSvgIcon-root": {
                              // アイコンサイズをカスタマイズする
                              fontSize: "10", // サイズ調整
                            },
                          }}
                        />
                      }
                      label="タイトル"
                      sx={{
                        ".MuiFormControlLabel-label": {
                          // ラベルのスタイリング
                          fontSize: "0.875rem", // フォントサイズの調整
                        },
                      }}
                    />
                    <FormControlLabel
                      value="author"
                      control={
                        <Radio
                          sx={{
                            color: "primary.main", // デフォルトカラー
                            "&.Mui-checked": {
                              color: "primary.main", // チェックされたときのカラー
                            },
                            "& .MuiSvgIcon-root": {
                              // アイコンサイズをカスタマイズする
                              fontSize: "10", // サイズ調整
                            },
                          }}
                        />
                      }
                      label="著者名"
                      sx={{
                        ".MuiFormControlLabel-label": {
                          // ラベルのスタイリング
                          fontSize: "0.875rem", // フォントサイズの調整
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Paper>

        {loading ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : (
          <List component="nav">
            {results.length > 0
              ? results.map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      button
                      component="a"
                      href={item["@id"]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            component="h1"
                            style={{ fontWeight: "bold", marginBottom: 20 }}
                          >
                            {item.title}
                          </Typography>
                        }
                        secondary={
                          <>
                            {item["dc:creator"]?.join(", ")} <br />
                            {`${item["prism:publicationName"]}${
                              item["prism:volume"]
                                ? ` ${item["prism:volume"]}`
                                : ""
                            }${
                              item["prism:number"]
                                ? ` (${item["prism:number"]})`
                                : ""
                            }${
                              item["prism:startingPage"]
                                ? `, p.${item["prism:startingPage"]}`
                                : ""
                            }${
                              item["prism:endingPage"]
                                ? `-${item["prism:endingPage"]}`
                                : ""
                            }, ${item["prism:publicationDate"]}`}
                          </>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))
              : !loading && (
                  <ListItem>
                    <ListItemText primary="検索結果が見つかりませんでした。" />
                  </ListItem>
                )}
          </List>
        )}
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            color="primary"
            style={{
              margin: "20px 0",
              justifyContent: "center",
              display: "flex",
            }}
          />
        )}
        <Box component="footer">
          <Paper
            elevation={3}
            style={{
              backgroundColor: "#2388e3",
              color: "white",
              padding: "1.5rem",
              textAlign: "center",
            }}
          >
            <Typography variant="body2">
              このサイトは
              <Link
                href="https://ci.nii.ac.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                CiNiiのAPI
              </Link>
              を利用しています。
            </Typography>
          </Paper>
        </Box>
        
      </Box>
      {/*<TutorialModal
  images={['tu1.png', 'tu2.png','tu3.png','tu4.png']}
  //tutorialId="unique-tutorial-id-for-page1"
          />*/}
    </>
  );
}

export default UserHome;
