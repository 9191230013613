import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const PrivateRoute = ({ children }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const isLoggedIn = () => {
    return sessionStorage.getItem('jwt') !== null && sessionStorage.getItem('userId') !== null;
  };

  useEffect(() => {
    if (!isLoggedIn()) {
      setOpen(true); // Snackbarを開く
      setTimeout(() => {
        navigate('/Login', { replace: true });
      }, 1500); // 2.5秒後にリダイレクト
    }
  }, [navigate]);

  if (isLoggedIn()) {
    return children;
  } else {
    return (
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          variant="filled"
        >
          ログインしてください。
        </Alert>
      </Snackbar>
    );
  }
};

export default PrivateRoute;
