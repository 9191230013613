import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  TableCell,
  TableRow,
  TableBody,
  Table,
  FormControl,
  TextField,
  Container,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TotalScore from "../../navigation/TotalScore";
import BasicInfoTable from "../../common/pdfTable/BasicInfoTable";

const MAX_SCORE = 30;

export default function Hasegawa() {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = React.useState({
    title: "改訂長谷川式簡易知能評価(HDR-R)",
    name: "",
    days: "",
    therapy: "",
    selectValue: "0",
    selectValue2: "0",
    selectValue3: "0",
    selectValue4: "0",
    selectValue5: "0",
    selectValue6: "0",
    text: "",
    text2: "",
    text3: "",
    text4: "",
    text5: "",
    text52: "",
    text6: "",
    text7: "",
    text8: "",
    text9: "",
    yearScore: "0",
    monthScore: "0",
    dayScore: "0",
    weekScore: "0",
    aSelect: "0",
    aSelect2: "0",
    bSelect: "0",
    bSelect2: "0",
    cSelect: "0",
    cSelect2: "0",
    Qselect: "0",
    Hselect: "0",
    vegetable1: "",
    vegetable2: "",
    vegetable3: "",
    vegetable4: "",
    vegetable5: "",
    vegetable6: "",
    vegetable7: "",
    vegetable8: "",
    vegetable9: "",
    vegetable10: "",
  });

  const initialFormData = {
    title: "改訂長谷川式簡易知能評価(HDR-R)",
    name: "",
    days: "",
    therapy: "",
    selectValue: "0",
    selectValue2: "0",
    selectValue3: "0",
    selectValue4: "0",
    selectValue5: "0",
    selectValue6: "0",
    yearScore: "0",
    monthScore: "0",
    dayScore: "0",
    weekScore: "0",
    aSelect: "0",
    aSelect2: "0",
    bSelect: "0",
    bSelect2: "0",
    cSelect: "0",
    cSelect2: "0",
    Qselect: "0",
    Hselect: "0",
    vegetable1: "",
    vegetable2: "",
    vegetable3: "",
    vegetable4: "",
    vegetable5: "",
    vegetable6: "",
    vegetable7: "",
    vegetable8: "",
    vegetable9: "",
    vegetable10: "",
  };
  const calculateTotalScore = () => {
    return (
      +formData.selectValue +
      +formData.selectValue2 +
      +formData.selectValue3 +
      +formData.selectValue4 +
      +formData.selectValue5 +
      +formData.selectValue6 +
      +formData.yearScore +
      +formData.monthScore +
      +formData.dayScore +
      +formData.weekScore +
      +formData.aSelect +
      +formData.aSelect2 +
      +formData.bSelect +
      +formData.bSelect2 +
      +formData.cSelect +
      +formData.cSelect2 +
      +formData.Qselect +
      +formData.Hselect
    );
  };

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }

  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
        // その他のフォームデータフィールド
      };

      const dataToSend = {
        ...formData,
        totalScore: calculateTotalScore().toString(),
        inputData: inputData, // ここでinputDataを追加
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/hasegawa`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "Hasegawa";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      }
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
            {}
          );

          // ステップ2: データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            const sessionData = response.data.data[0].inputData;
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]);

  const handleRefresh = () => {
    setFormData(initialFormData);
  };

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          長谷川式簡易知能評価スケール（HDS）
        </Typography>
        <Typography variant="h6" gutterBottom>
          基本
        </Typography>
        <BasicInfoTable
          formData={formData}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <Box sx={{ paddingLeft: "5px", paddingBottom: "2px" }}></Box>
        <Typography variant="h6" gutterBottom>
          評価
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "one",
                  label: " 1. お年はいくつですか？",
                  instruction: "採点:（2年までの誤差は正解）",
                },
                {
                  id: "two",
                  label: "2.今日は何年の何月何日ですか？何曜日ですか？",
                  instruction: "採点:（年月日、曜日が正解でそれぞれ1点）",
                },
                {
                  id: "three",
                  label: "3.私達が今いるところがどこですか？",
                  instruction:
                    "採点:\n（自発的に出れば2点、5秒おいて、家ですか？病院ですか？施設ですか？の中から正しいと選択すれば1点）",
                },
                {
                  id: "four",
                  label:
                    "4.これから言う3つの言葉を言ってみて下さい。後でまた聞きますので良く覚えていておいて下さい。",
                  instruction:
                    "採点:（1つずつに採点して下さい） \n\na）桜 b）猫 c）電車 　 a）梅 b）犬 c）自動車",
                },
                {
                  id: "five",
                  label: "5. 100から7を順番に引いて下さい。",
                  instruction:
                    "採点:\n（100-7は？それからまた7引くと？と質問する。最初の答が不正解の場合は打ち切る。）",
                },
                {
                  id: "six",
                  label: "6. 私がこれから言う数字を逆から言って下さい。",
                  instruction:
                    "採点:（3桁逆唱に失敗したら打ち切る。）\n（6-8-2）（3-5-2-9）",
                },
                {
                  id: "seven",
                  label:
                    "7. 先ほど覚えてもらった言葉をもう一度言ってみて下さい。",
                  instruction:
                    "採点:（自発的回答が出れば2点、下記のヒントを与えて正解すれば1点）\n【a)植物b)動物c)乗り物】",
                },
                {
                  id: "eight",
                  label:
                    "8.これから5つの品物を見せます。それを隠しますので何があったか言って下さい。",
                  instruction:
                    "採点: （時計、鍵、タバコ、ペン、硬貨など必ず相互に無関係なもの。）",
                },
                {
                  id: "nine",
                  label:
                    " 9. 知っている野菜の名前をできるだけ多く言って下さい。",
                  instruction:
                    "採点:\n（答えた野菜の名前を欄に記載する。途中で詰まり、約10秒待っても出ない場合にはそこで打ち切る。）\n 5個までは0点、6個=1点、7個=2点、8個=3点、9個=4点、10個=5点",
                },
                // ... その他のデータ
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "35%",
                      color: "white",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "one" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <Select
                                  name="selectValue"
                                  value={formData.selectValue}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "two" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>年: </label>
                                <Select
                                  name="yearScore"
                                  value={formData.yearScore}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>月: </label>
                                <Select
                                  name="monthScore"
                                  value={formData.monthScore}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>日: </label>
                                <Select
                                  name="dayScore"
                                  value={formData.dayScore}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>曜日: </label>
                                <Select
                                  name="weekScore"
                                  value={formData.weekScore}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "three" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <Select
                                  name="selectValue2"
                                  value={formData.selectValue2}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                  <MenuItem value={"2"}>2</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "four" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>a: </label>
                                <Select
                                  name="aSelect"
                                  value={formData.aSelect}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>b: </label>
                                <Select
                                  name="bSelect"
                                  value={formData.bSelect}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>c: </label>
                                <Select
                                  name="cSelect"
                                  value={formData.cSelect}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "five" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>93: </label>
                                <Select
                                  name="Qselect"
                                  value={formData.Qselect}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>86: </label>
                                <Select
                                  name="Hselect"
                                  value={formData.Hselect}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "six" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>（2-8-6）: </label>
                                <Select
                                  name="selectValue3"
                                  value={formData.selectValue3}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>（9-2-5-3）: </label>
                                <Select
                                  name="selectValue4"
                                  value={formData.selectValue4}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "seven" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>a: </label>
                                <Select
                                  name="aSelect2"
                                  value={formData.aSelect2}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                  <MenuItem value={"2"}>2</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>b: </label>
                                <Select
                                  name="bSelect2"
                                  value={formData.bSelect2}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                  <MenuItem value={"2"}>2</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>c: </label>
                                <Select
                                  name="cSelect2"
                                  value={formData.cSelect2}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                  <MenuItem value={"2"}>2</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {row.id === "eight" && (
                          <>
                            <Grid>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <Select
                                  name="selectValue5"
                                  value={formData.selectValue5}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                  <MenuItem value={"2"}>2</MenuItem>
                                  <MenuItem value={"3"}>3</MenuItem>
                                  <MenuItem value={"4"}>4</MenuItem>
                                  <MenuItem value={"5"}>5</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {row.id === "nine" && (
                          <>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable1"
                                  label="野菜名"
                                  value={formData.vegetable1}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable2"
                                  label="野菜名"
                                  value={formData.vegetable2}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable3"
                                  label="野菜名"
                                  value={formData.vegetable3}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable4"
                                  label="野菜名"
                                  value={formData.vegetable4}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable5"
                                  label="野菜名"
                                  value={formData.vegetable5}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable6"
                                  label="野菜名"
                                  value={formData.vegetable6}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable7"
                                  label="野菜名"
                                  value={formData.vegetable7}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable8"
                                  label="野菜名"
                                  value={formData.vegetable8}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable9"
                                  label="野菜名"
                                  value={formData.vegetable9}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  size="small"
                                  name="vegetable10"
                                  label="野菜名"
                                  value={formData.vegetable10}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>

                            <Grid sx={{ marginTop: "10px" }}>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 100 }}
                              >
                                <label>点数:</label>
                                <Select
                                  name="selectValue6"
                                  value={formData.selectValue6}
                                  onChange={handleChange}
                                  displayEmpty
                                  sx={{ marginBottom: "5px" }}
                                >
                                  <MenuItem value={"0"}>0</MenuItem>
                                  <MenuItem value={"1"}>1</MenuItem>
                                  <MenuItem value={"2"}>2</MenuItem>
                                  <MenuItem value={"3"}>3</MenuItem>
                                  <MenuItem value={"4"}>4</MenuItem>
                                  <MenuItem value={"5"}>5</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        <Grid sx={{ marginTop: "8px" }}>
                          <Typography
                            sx={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            {row.instruction
                              .split("\n")
                              .map((str, index, array) => (
                                <span key={index}>
                                  {str}
                                  {index !== array.length - 1 && <br />}
                                </span>
                              ))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TotalScore formData={formData} maxScore={MAX_SCORE} />
          <Button
            variant="contained"
            color="success"
            sx={{
              position: "fixed",
              right: [10, 50, 100],
              top: [150, 150, 100],
              size: ["small", "medium", "large"],
              zIndex: 11,
            }}
            onClick={handleSaveDraft}
          >
            一時保存
          </Button>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>

        {pdfFilePath && (
          <PdfModal
            open={isPdfModalOpen}
            onClose={() => setIsPdfModalOpen(false)}
            pdfFilePath={pdfFilePath}
          />
        )}
        <FixedBottomNavigation
          editPDF={editPDF}
          handleRefresh={handleRefresh}
        />
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
