import { Table, TableBody } from "@mui/material";
import TextTableRow from "./TextTableRow";

const TextTable = ({ rows, formData, handleChange }) => {
  return (
    <Table>
      <TableBody>
        {rows.map((row, index) => (
          <TextTableRow
            key={index}
            row={row}
            formData={formData}
            handleChange={handleChange}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default TextTable;
