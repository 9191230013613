import React, { useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";


const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const drawerRef = useRef(null);

  const menuItems = [
    {
      name: "看護師",
      icon: <VaccinesIcon color="primary" />,
      path: "/Nurse",
    },
    {
      name: "理学療法士",
      icon: <DirectionsRunIcon color="primary" />,
      path: "/Physical",
    },
    {
      name: "作業療法士",
      icon: <PsychologyAltIcon color="primary" />,
      path: "/Occupational",
    },
    {
      name: "言語聴覚士",
      icon: <RecordVoiceOverIcon color="primary" />,
      path: "/Speech",
    },
  ];
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (open && !drawerRef.current.contains(event.target)) {
        handleDrawerClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    // セッションストレージからuserIdとJWTを削除
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('jwt');
  
    // ログアウト後、ユーザーをログインページなどにリダイレクトする
    window.location.href = '/';
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "flex", sm: "flex" }, flexGrow: 1 }}>
            <Link
              to="/Dashboard"
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={process.env.PUBLIC_URL + "/pdf-app-logo.png"}
                alt="PDF App Logo"
                sx={{ mr: 0.8, mt: 0.5, width: 25, height: 25 }}
              />
            </Link>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              評価用紙キット
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={handleLogout}
            
          >
            ログアウト
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        ref={drawerRef}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          

          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor:"#1976d2",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{backgroundColor:"#1976d2"}}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
           padding: 1,
            borderBottom: "1px solid #1976d2",
            backgroundColor:"white"          }}
        >
          <Link
            to="/Dashboard"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListItem key="過去のPDF" disablePadding sx={{backgroundColor:"white"}}>
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon>
                <PictureAsPdfIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="過去のPDF" />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>

        <List sx={{ padding: "0" }}>
          {menuItems.map((menuItem, index) => (
            <ListItem
              key={menuItem.name}
              disablePadding
              sx={{
                borderBottom:
                  index !== menuItems.length - 1 ? "1px solid #1976d2" : "none",p:1,backgroundColor:"white"
              }}
            >
              <ListItemButton
                component={Link}
                to={menuItem.path}
                onClick={handleDrawerClose}
              >
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
       
        <List
          sx={{
            paddingTop: 2,
            paddingBottom: 2,
        
            backgroundColor:"white",
            borderTop: "1px solid #1976d2",
            
          }}
        >
         
          <Link
            to="/UserHome"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListItem key="論文検索" disablePadding>
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon>
                  <PlagiarismIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="論文検索" />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link
            to="/AiPage"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListItem key="AI" disablePadding>
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon>
                  <CloudDownloadIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="AI・GPT" />
              </ListItemButton>
            </ListItem>
          </Link>
        
        </List>


        <Divider />
      </Drawer>

      <Main open={open}>
        <Backdrop
          open={open}
          onClick={handleDrawerClose}
          sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
        />
        <DrawerHeader />
      </Main>
    </Box>
  );
}
