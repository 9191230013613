import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export default function DefaultAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
        <Box sx={{ display: { xs: "flex", sm: "flex" }, flexGrow: 1 }}>
  <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
    <Box
      component="img"
      src={process.env.PUBLIC_URL + "/pdf-app-logo.png"}
      alt="PDF App Logo"
      sx={{ mr: 0.8, mt: 0.5, width: 25, height: 25 }}
    />
    <Typography
      variant="h6"
      noWrap
      component="div"
      sx={{ display: { sm: "block" } }}
    >
      評価用紙キット
    </Typography>
  </Link>
</Box>

          <Button 
          component={Link}
          to="/Login"
        color="inherit" 
        variant="text" 
        sx={{
          width: '100px', // ボタンの幅を固定
          fontWeight: "bold !important",
          mr: 1, // 右側のマージン追加
          "@media (max-width:600px)": {
            
            mr: 0,
            fontSize:"14px",
          },
        }}
      >
        ログイン
      </Button>
      <Button 
      component={Link}
      to="/Register"
        color="primary" 
        variant="contained" 
        sx={{
          width: '100px', // 同様にボタンの幅を固定
          fontWeight: "bold !important",
          
          "@media (max-width:600px)": {
            
            fontSize:"14px",
            
          },
        }}
      >
        登録
      </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
