import * as React from "react";
import { useState, useEffect } from "react";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  Container,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableSortLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  useMediaQuery,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import TutorialModal from "../components/navigation/TutorialModal";
import axios from "axios";
import SessionCard from "../components/common/SessionCard";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.breakpoints.down("xs") ? 11 : 14,
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.breakpoints.down("xs") ? 11 : 14, // xs以下で11、それ以外で13
  },
  "& .MuiTableSortLabel-root": {
    color: `${theme.palette.common.white} !important`,
    fontSize: theme.breakpoints.down("xs") ? 11 : 14, // 通常のテキスト色を強制的に白に設定
  },
}));

const HoverTableRow = styled(({ isDraft, ...otherProps }) => (
  <TableRow {...otherProps} />
))(({ theme, isDraft }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  backgroundColor: isDraft ? "#EEEEEE" : "white",
  "&:hover": {
    backgroundColor: isDraft ? "#FF82B2" : "#e0e0e0",
    cursor: "pointer",
  },
}));

const sortOptions = [
  {
    key: "createdAt-asc",
    icon: <AccessTimeIcon />,
    title: "古い順",
    sortValue: "createdAt-asc",
  },
  {
    key: "createdAt-desc",
    icon: <AccessTimeIcon />,
    title: "新しい順",
    sortValue: "createdAt-desc",
  },

  {
    key: "status-asc",
    icon: <SaveAltIcon />,
    title: "一時保存",
    sortValue: "status-asc",
  },
  {
    key: "status-desc",
    icon: <PictureAsPdfIcon />,
    title: "確定",
    sortValue: "status-desc",
  },
];

const tutorialImages = [
  { imgPath: "tu1.png", label: "実装中" },
  { imgPath: "tu2.png", label: "実装中" },
  { imgPath: "tu3.png", label: "実装中" },
  { imgPath: "tu4.png", label: "実装中" },
];

const axiosInstance = axios.create({
  baseURL: "https://pdf-app-a38cc5e2c287.herokuapp.com",
});

export default function SavedSessionsTable() {
  const [sessions, setSessions] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [currentPdfId, setCurrentPdfId] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchColumn, setSearchColumn] = useState("title");
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");

  const customIcon = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "-7px",
      }}
    >
      <TuneIcon />
      <Typography variant="caption" style={{ fontSize: "0.6rem" }}>
        並べ替え
      </Typography>
    </div>
  );

  useEffect(() => {
    const fetchDashboardData = async () => {
      const userId = sessionStorage.getItem("userId");
      if (userId) {
        try {
          const response = await axiosInstance.get(
            `/get-dashboard-data/${userId}`
          );

          if (response.data.success) {
            setSessions(response.data.sessions);
            setPdfs(response.data.pdfs);
          } else {
            console.error(
              "予期しないAPIレスポンスフォーマット:",
              response.data
            );
          }
        } catch (error) {
          console.error(
            "ダッシュボードデータの取得中にエラーが発生しました:",
            error
          );
        }
      } else {
        console.error(
          "userIdがnullです。ダッシュボードデータを取得できません。"
        );
      }
    };

    fetchDashboardData();
  }, []);

  const handleEdit = (session) => {
    const pageName = getPageNameFromTitle(session.title) || "defaultPageName";

    if (session && session.id) {
      window.location.href = `https://assessment-pdf-app.com/${pageName}?sessionId=${session.id}`;
    } else {
      console.error(
        "有効なセッションオブジェクトが提供されていません。:",
        session
      );
    }
  };

  // 'title' プロパティからページ名を取得する関数
  function getPageNameFromTitle(title) {
    const mappings = {
      "改訂長谷川式簡易知能評価(HDR-R)": "Hasegawa",
      OHAT_J: "OHAT_J",
      整形外科的テスト評価表: "SeikeiTest",
      "BathelIndex(BI)": "BathelIndex",
      FIM: "Fim",
      "Berg Balance Scale(BBS)": "BBS",
      "粗大筋力検査(GMT)": "GMT",
      "関節可動域(ROM)上肢": "ROMArm",
      "関節可動域(ROM)下肢・体幹": "ROMLeg",
      "関節可動域(ROM)手指": "ROMFinger",
      "パーキンソン病の重症度(Hoehn-Yahr重症度分類 & 生活機能障害度)": "Yahr",
      "Gugging Swallowing Screen(GUSS)": "GUSS",
      "ALS機能評価スケール改訂版(ALSFRS-R)": "ALSFRS",
      "簡易栄養状態評価表(MNA)": "MNA",

      // 他のタイトルとページ名のマッピングを追加
    };

    return mappings[title];
  }

  const confirmDelete = (sessionId) => {
    setCurrentSessionId(sessionId);
    setDeleteDialogOpen(true);
  };

  const handleView = async (gcsUrl) => {
    try {
      window.open(gcsUrl, "_blank");
    } catch (error) {
      console.error("Error opening the PDF URL:", error);
      alert("エラーが発生しました。");
    }
  };

  const confirmPdfDelete = (pdfId) => {
    setCurrentPdfId(pdfId);
    setDeleteDialogOpen(true);
  };

  const deletePdf = async (pdfId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/delete-pdf/${pdfId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      if (response.headers.get("Content-Type").includes("application/json")) {
      } else {
        console.error("Non-JSON response received.");
      }
    } catch (error) {
      console.error("Error deleting PDF:", error);
      alert("削除中にエラーが発生しました。");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/delete-session/${currentSessionId}`
      );
      if (response.status === 200 && response.data.success) {
        const updatedSessions = sessions.filter(
          (session) => session._id !== currentSessionId
        );
        setSessions(updatedSessions);
      } else {
        alert("セッションの削除中にエラーが発生しました。");
      }
    } catch (error) {
      console.error(error);
      alert("エラーが発生しました。");
    }
    setCurrentSessionId(null);
    setDeleteDialogOpen(false);
  };

  const handlePdfDelete = async () => {
    // 追加
    try {
      await deletePdf(currentPdfId);
      const updatedPdfs = pdfs.filter((pdf) => pdf._id !== currentPdfId);
      setPdfs(updatedPdfs);
    } catch (error) {
      alert("PDFの削除中にエラーが発生しました。");
    }
    setCurrentPdfId(null);
    setDeleteDialogOpen(false);
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const unifiedData = () => {
    const sessionData = sessions.map((session) => ({
      id: session._id,
      title: session.inputData.title,
      status: "一時保存",
      createdAt: session.createdAt,
      type: "session",
      inputData: session.inputData,
    }));

    const pdfData = pdfs.map((pdf) => ({
      id: pdf._id,
      title: pdf.title,
      status: "確定",
      createdAt: pdf.createdAt,
      type: "pdf",
      gcsUrl: pdf.gcsUrl,
      inputData: pdf.inputData,
    }));
    return [...sessionData, ...pdfData];
  };

  function convertToJapaneseEra(date) {
    const eraMap = [
      { start: new Date("2019-05-01"), era: "令和", yearOffset: 2018 },
    ];

    for (const { start, era, yearOffset } of eraMap) {
      if (date >= start) {
        const year = date.getFullYear() - yearOffset;
        return `${era}${year}年${
          date.getMonth() + 1
        }月${date.getDate()}日${date.getHours()}時${date.getMinutes()}分`;
      }
    }
    return date.toLocaleDateString();
  }

  const handleSortChange = (sortOption) => {
    const [newOrderBy, newOrder] = sortOption.split("-");
    setOrderBy(newOrderBy);
    setOrder(newOrder);
    setOpen(false);
  };

  // 並べ替えのための関数をここに追加
  const descendingComparator = (a, b, orderBy) => {
    const aValue = orderBy.includes(".")
      ? getNestedValue(a, orderBy)
      : a[orderBy];
    const bValue = orderBy.includes(".")
      ? getNestedValue(b, orderBy)
      : b[orderBy];

    if (typeof aValue === "number" && typeof bValue === "number") {
      return bValue - aValue;
    }
    if (typeof aValue === "string" && typeof bValue === "string") {
      return bValue.localeCompare(aValue, "ja");
    }
    return 0;
  };

  // ネストされたオブジェクトの値を取得するためのヘルパー関数
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const filteredData = () => {
    return unifiedData().filter((item) => {
      const itemValue = searchColumn.includes(".")
        ? getNestedValue(item, searchColumn)
        : item[searchColumn];

      return itemValue
        ? itemValue.toString().toLowerCase().includes(searchQuery.toLowerCase())
        : false;
    });
  };

  const dataToDisplay = stableSort(
    filteredData(),
    getComparator(order, orderBy)
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // 検索カラムの選択を処理
  const handleColumnChange = (event) => {
    setSearchColumn(event.target.value);
  };

  return (
    <div>
      <Container sx={{ minWidth: "100%", mb: 8 }}>
        {isMobile && (
          <SpeedDial
            ariaLabel="並べ替えオプション"
            sx={{ position: "fixed", bottom: "10%", right: "5%" }}
            icon={<SpeedDialIcon icon={customIcon} openIcon={customIcon} />} // 開閉時のアイコンを同じにする
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            direction="up"
          >
            {sortOptions.map((option) => (
              <SpeedDialAction
                key={option.key}
                icon={option.icon}
                tooltipTitle={option.title}
                tooltipOpen={open}
                onClick={() => handleSortChange(option.sortValue)}
              />
            ))}
          </SpeedDial>
        )}

        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <TextField
                label="検索"
                variant="outlined"
                fullWidth
                value={searchQuery}
                placeholder="検索..."
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ maxWidth: { xs: "60%", sm: "100%" } }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <InputLabel>絞り込み</InputLabel>
                <Select
                  value={searchColumn}
                  label="絞り込み"
                  onChange={handleColumnChange}
                >
                  <MenuItem value="title">評価用紙</MenuItem>
                  <MenuItem value="inputData.name">患者氏名</MenuItem>
                  <MenuItem value="status">ステータス</MenuItem>
                  <MenuItem value="createdAt">作成日</MenuItem>
                  {/* 他のカラムのオプションを追加 */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {isMobile ? (
          dataToDisplay.map((item) => (
            <SessionCard
              key={item.id}
              item={item}
              onEdit={handleEdit}
              onView={() => handleView(item.gcsUrl)}
              onDelete={() =>
                item.type === "session"
                  ? confirmDelete(item.id)
                  : confirmPdfDelete(item.id)
              }
            />
          ))
        ) : (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: "800px", overflowX: "auto" }}
          >
            <Table aria-label="saved sessions table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">
                    <TableSortLabel
                      active={orderBy === "title"}
                      direction={orderBy === "title" ? order : "asc"}
                      onClick={() => handleSort("title")}
                    >
                      評価用紙
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <TableSortLabel
                      active={orderBy === "inputData.name"}
                      direction={orderBy === "inputData.name" ? order : "asc"}
                      onClick={() => handleSort("inputData.name")}
                    >
                      患者氏名
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === "status"}
                      direction={orderBy === "status" ? order : "asc"}
                      onClick={() => handleSort("status")}
                    >
                      ステータス
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === "createdAt"}
                      direction={orderBy === "createdAt" ? order : "asc"}
                      onClick={() => handleSort("createdAt")}
                    >
                      作成日
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ color: "white" }}>
                    アクション
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataToDisplay.map((item) => {
                  return (
                    <HoverTableRow
                      key={item.id}
                      isDraft={item.type === "session"}
                      onClick={() => {
                        if (item.type === "session") {
                          handleEdit(item);
                        } else {
                          handleView(item.gcsUrl);
                        }
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {item.title}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.inputData?.name || "未設定"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.createdAt
                          ? convertToJapaneseEra(new Date(item.createdAt))
                          : "-"}
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <Button
                          variant="contained"
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation();
                            item.type === "session"
                              ? confirmDelete(item.id)
                              : confirmPdfDelete(item.id);
                          }}
                        >
                          削除
                        </Button>
                      </StyledTableCell>
                    </HoverTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>本当に一時保存を削除しますか？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDelete} color="error">
            削除
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen && currentPdfId !== null}
        onClose={() => {
          setDeleteDialogOpen(false);
          setCurrentPdfId(null);
        }}
      >
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>本当にこのPDFを削除しますか？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              setCurrentPdfId(null);
            }}
            color="primary"
          >
            キャンセル
          </Button>
          <Button onClick={handlePdfDelete} color="error">
            削除
          </Button>
        </DialogActions>
      </Dialog>
      <TutorialModal
        images={tutorialImages}
        tutorialId="page1"
      />
    </div>
  );
}
