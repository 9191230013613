import React from "react";
import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";

function HomeService() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const pdfimgs = [
    {
      service: "SERVICE 01",
      title: "職種別の評価用紙",
      description:
        "職種、科目に合わせて必要な評価用紙をすぐ作成可能です。現在合計30枚以上の評価用紙が登録されています。",
      image: "pdf1.png",
    },

    {
      service: "SERVICE 02",
      title: "管理画面",
      description:
        "「一時保存」「確定」した評価用紙を一覧で確認できます。並べ替えも可能です。",
      image: "pdf2.png",
    },
    {
      service: "SERVICE03",
      title: "生成AIで翻訳・要約",
      description:
        "論文をアップロードすると生成AIが文章を解析し自然言語処理で翻訳、要約してくれます。論文に対して質問すること可能です。",
      image: "pdf3.png",
    },

    {
      service: "SERVICE 04",
      title: "論文検索",
      description:
        "評価用紙アプリ内で簡単に論文を検索できます。※CiNiiを利用しています。",
      image: "pdf4.png",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        flexWrap: "wrap",
        gap: 3,
        mb: isSmallScreen ? 0 : 8,
        p: 3,
        mx: 2,
        overflow: "hidden",
      }}
    >
      {pdfimgs.map((pdfimg, index) => (
        <Box
          className="pdfimg"
          key={index}
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column-reverse" : "row", // 画面サイズに応じて方向変更
            alignItems: "flex-start",
            justifyContent: "center",
            p: 3,
            mx: 2,
            width: "100%",
            maxWidth: "900px", // コンテナの最大幅を調整
            margin: "auto",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/${pdfimg.image}`}
            alt={pdfimg.title}
            style={{
              width: isSmallScreen ? "300px" : "400px",
              height: isSmallScreen ? "300px" : "400px",
              objectFit: "cover",
              marginRight: isSmallScreen ? "0" : "20px",
              border: "2px solid #1976d2",
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: isSmallScreen ? "300px" : "400px",
              height: isSmallScreen ? "auto" : "400px",
            }}
          >
            {isSmallScreen ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  component="h3"
                  sx={{ color: "#1976d2", borderBottom: "solid 3px #1976d2" }}
                >
                  {pdfimg.service.slice(0, -2)}
                  <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                    {pdfimg.service.slice(-2)}
                  </span>
                </Typography>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ fontWeight: "bold", color: "#1976d2", ml: 1 }}
                >
                  {pdfimg.title}
                </Typography>
              </Box>
            ) : (
              <>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{ color: "#1976d2", borderBottom: "solid 4px #1976d2" }}
                >
                  {pdfimg.service.slice(0, -2)}
                  <span style={{ fontSize: "70px", fontWeight: "bold" }}>
                    {pdfimg.service.slice(-2)}
                  </span>
                </Typography>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{ fontWeight: "bold", color: "#1976d2" }}
                >
                  {pdfimg.title}
                </Typography>
              </>
            )}
            <Typography
              variant={isSmallScreen ? "body2" : "h5"}
              sx={{
                mt: 2,
                mb: 2,
                lineHeight: "1.5",
                fontWeight: "bold",
                color: "#424242",
              }}
            >
              {pdfimg.description}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default HomeService;
