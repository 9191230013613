import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function PdfModal({ open, onClose, pdfFilePath }) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // ユーザーエージェントをチェックしてスマホかどうかを判別
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="sm"
            fullScreen={fullScreen}
            sx={{zIndex:2000}}
        >
            {isMobile ? (
            <div style={{   ml:1,
                mr:1,display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '45vh' }}>
            <a 
                href={pdfFilePath} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{
                    color: 'blue', 
                    textDecoration: 'none', 
                    padding: '10px', 
                    border: '1px solid blue',
                    borderRadius: '5px',
                    textAlign: 'center',
                    "&:hover": {
                        color: 'white',
                        backgroundColor: theme.palette.primary.main, // primary色を使用
                    }
                }}
            >
                PDFを表示する
            </a>
        </div>
        
          
            ) : (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer
                       fileUrl={pdfFilePath}
                        plugins={[defaultLayoutPluginInstance]}
                    />
                </Worker>
            )}
            <DialogActions>
                <Button onClick={onClose}variant='outlined' color="primary">
                    閉じる
                </Button>
                
            </DialogActions>
        </Dialog>
    );
}

export default PdfModal;
