import React, { useState } from "react";
import {
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Grid,
  FormControl,
  TextField,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";



const BasicInfoTable = ({ formData, handleChange, handleBlur }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Table aria-label="basic info table">
      <TableBody>
        {[
          {
            label: "氏名",
            name: "name",
            type: "text",
            helperText:
              "※入力された氏名は 例:「○田○郎」に変換されます。",
          },
          { label: "日付", name: "days", type: "date" },
          { label: "検者", name: "therapy", type: "text" },
        ].map((row, index) => (
          <TableRow key={index}>
            <TableCell
              sx={{ color: "white", backgroundColor: "#5972ff", width: "25%" }}
            >
              {row.label}
            </TableCell>
            <TableCell align="left" sx={{ padding: 0 }}>
              <Box sx={{ pl: 3 }}>
                <Grid container direction="column" alignItems="flex-start">
                  <FormControl variant="outlined" size="small">
                    {row.name === "name" ? (
                      isMobile ? (
                        // スマートフォン用のポップオーバー
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            name={row.name}
                            size="small"
                            variant="outlined"
                            value={formData[row.name]}
                            onChange={handleChange}
                            type={row.type}
                            onBlur={handleBlur}
                            sx={{ width: "195px", flexGrow: 1, mr: 1 }}
                          />
                          <IconButton onClick={handleClick}>
                            <HelpOutlineIcon color ="error"/>
                          </IconButton>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography sx={{ p: 2 }}>
                              {row.helperText}
                            </Typography>
                          </Popover>
                        </Box>
                      ) : (
                        // ポップオーバーコンポーネント
                        <Tooltip title={row.helperText} placement="top">
                          <TextField
                            name={row.name}
                            size="small"
                            variant="outlined"
                            value={formData[row.name]}
                            onChange={handleChange}
                            type={row.type}
                            onBlur={handleBlur}
                            sx={{ width: "195px" }}
                          />
                        </Tooltip>
                      )
                    ) : (
                      <TextField
                        name={row.name}
                        size="small"
                        variant="outlined"
                        value={formData[row.name]}
                        onChange={handleChange}
                        type={row.type}
                        helperText={row.helperText}
                        sx={{
                          width: "195px",
                          "& .MuiFormHelperText-root": { color: "red" },
                        }}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BasicInfoTable;
