import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  TableCell,
  TableRow,
  TableBody,
  Table,
  FormControl,
  TextField,
  Container,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  useTheme,
  Popover,
  Tooltip,
  FormLabel,
  CircularProgress,
  Backdrop,
} from "@mui/material";

import FixedBottomNavigation from "../../navigation/BottomNavigation";
import PdfModal from "../../navigation/PdfModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function Seikei_Test() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const [formData, setFormData] = React.useState({
    title: "整形外科的テスト評価表",
    name: "",
    days: "",
    sV1: "",
    sV12: "",
    sV2: "",
    sV22: "",
    sV3: "",
    sV32: "",
    sV4: "",
    sV42: "",
    sV5: "",
    sV52: "",
    sV6: "",
    sV62: "",
    sV7: "",
    sV72: "",
    sV8: "",
    sV82: "",
    sV9: "",
    sV92: "",
    sV10: "",
    sV102: "",
    sV11: "",
    sV112: "",
    sV1212: "",
    sV12122: "",
    sV1313: "",
    sV13132: "",
    sV1414: "",
    sV14142: "",
    sV1515: "",
    sV15152: "",
  });

  const initialFormData = {
    title: "整形外科的テスト評価表",
    name: "",
    days: "",
    sV1: "",
    sV12: "",
    sV2: "",
    sV22: "",
    sV3: "",
    sV32: "",
    sV4: "",
    sV42: "",
    sV5: "",
    sV52: "",
    sV6: "",
    sV62: "",
    sV7: "",
    sV72: "",
    sV8: "",
    sV82: "",
    sV9: "",
    sV92: "",
    sV10: "",
    sV102: "",
    sV11: "",
    sV112: "",
    sV1212: "",
    sV12122: "",
    sV1313: "",
    sV13132: "",
    sV1414: "",
    sV14142: "",
    sV1515: "",
    sV15152: "",
  };

  const [pdfFilePath, setPdfFilePath] = useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open1 = Boolean(anchorEl);
  const id = open1 ? "simple-popover" : undefined;

 const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    if (name === "name") {
      const convertedValue = convertName(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: convertedValue,
      }));
    }
  };

  function convertName(name) {
    return name
      .split("")
      .map((char, index) => (index % 2 === 0 ? "○" : char))
      .join("");
  }


  const editPDF = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("jwt");

      const inputData = {
        name: formData.name,
      };

      const dataToSend = {
        ...formData,

        inputData: inputData, // 合計点を文字列として送信
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/edit-pdf/Seikei_Test`,
        dataToSend,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const gcsUrl = `https://storage.googleapis.com/user-pdf-files/${response.data}`;

      setPdfFilePath(gcsUrl);
      setIsPdfModalOpen(true);
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);

        if (
          error.response.status === 401 ||
          error.response.data === "ログインしてください"
        ) {
          window.alert("ログインしてください。");
          window.location.href = "/";
        }
      }
    } finally {
      setLoading(false); // 非同期処理が完了したらローディング状態をfalseに設定
    }
  };

  const handleSaveDraft = async () => {
    
    const userId = sessionStorage.getItem("userId");
    if (!userId || userId === "undefined") {
      alert("ユーザーIDが取得できませんでした。再度ログインしてください。");
      return;
    }

    const pageName = "SeikeiTest";

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/save-session`,
      {
        userId: userId, // ログイン中のユーザーIDをセット
        inputData: formData,
        status: "draft",
        pageName: pageName,
      },
      
    );

    if (response.data.success) {
      setAlertMessage("一時保存しました！");
      setAlertSeverity("success");
    } else {
      setAlertMessage("エラーが発生しました。");
      setAlertSeverity("error");
    }
    setOpen(true);
  };
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("sessionId");

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionId) {
        try {
          
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-session-by-id/${sessionId}`,
           
          );

          // データの存在を確認
          if (response.data.data && response.data.data.length > 0) {
            // セッションデータを取得
            const sessionData = response.data.data[0].inputData;

            // フォームデータにセッションデータを設定
            setFormData(sessionData);
          } else {
            console.error("セッションデータが存在しません。");
          }
        } catch (error) {
          console.error(
            "セッションデータの取得に失敗しました:",
            error.message,
            error.response ? error.response.data : ""
          );
        }
      }
    };

    fetchSessionData();
  }, [sessionId]); // 依存配列にsessionIdを指定

  const handleRefresh = () => {
    setFormData(initialFormData);
  };

  return (
    <Container>
      <Box maxWidth="md">
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          整形外科的テスト評価表
        </Typography>
        <Typography variant="h5" gutterBottom>
          基本
        </Typography>
        <Table aria-label="simple table">
          <TableBody>
            {[
              { label: "氏名", type: "named" },
              { label: "日付", type: "date" },
            ].map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    color: "white",
                    backgroundColor: "#5972ff",
                    width: "25%",
                  }}
                >
                  {row.label}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{  padding: 0 }}
                >
                  <Box sx={{ pl: 3 }}>
                    <Grid container direction="column" alignItems="flex-start">
                      <FormControl variant="outlined" size="small">
                        {row.type === "named" && (
                          isMobile ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <TextField
                                name="name"
                                size="small"
                                variant="outlined"
                                value={formData.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                sx={{
                                  width:"195px",
                                }}
                              />
                              <IconButton onClick={handleClick}>
                                <HelpOutlineIcon color ="error"/>
                              </IconButton>
                              <Popover
                                id={id}
                                open={open1}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                ※入力された氏名は 例:「○田○郎」に変換されます。
                                </Typography>
                              </Popover>
                            </Box>
                          ) : (
                            <Tooltip title= "※入力された氏名は 例:「○田○郎」に変換されます。" placement="top">
                              <TextField
                                name="name"
                                size="small"
                                variant="outlined"
                                value={formData.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                sx={{
                                  width:"195px",
                                }}
                              />
                            </Tooltip>
                          )
                        )}
                        {row.type === "date" && (
                          <TextField
                            name="days"
                            size="small"
                            variant="outlined"
                            value={formData.days}
                            onChange={handleChange}
                            type="date"
                            sx={{
                              width: "195px",
                            }}
                          />
                        )}
                      </FormControl>
                    </Grid>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Box
          sx={{ paddingLeft: "5px", paddingBottom: "2px", paddingTop: "4px" }}
        ></Box>
        <Typography variant="h5" gutterBottom>
          評価
        </Typography>
        <Typography variant="h6" gutterBottom>
          上肢
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "one",
                  label: "Drop arm Sign",
                  description: "・棘上・下筋腱の異常",
                },
                {
                  id: "two",
                  label: "インピンジメントサイン",
                  description: "・インピンジメント異常",
                },
                {
                  id: "three",
                  label: "Lift off test",
                  description: "・肩甲下筋腱の異常",
                },
                {
                  id: "four",
                  label: "外反ストレステスト",
                  description: "・肘内側側副靱帯の異常",
                },
                {
                  id: "five",
                  label: "内反ストレステスト",
                  description: "・肘外側側副靭帯の異常",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "36%",
                      color: "white",
                    }}
                  >
                    {row.label}{" "}
                    <div style={{ paddingTop: "10px" }}>{row.description}</div>
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "one" && (
                          <>
                            <Box sx={{ marginLeft: 3 }}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV1"
                                  value={formData.sV1}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio  />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3 }}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV12"
                                  value={formData.sV12}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio/>}
                                    label={<span style={{ fontWeight: 'bold',  }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}

                        {row.id === "two" && (
                          <>
                            <Box sx={{ marginLeft: 3}}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV2"
                                  value={formData.sV2}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3,}}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV22"
                                  value={formData.sV22}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold',  }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}

                        {row.id === "three" && (
                          <>
                            <Box sx={{ marginLeft: 3,}}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV3"
                                  value={formData.sV3}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio  />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="陰性"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3}}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV32"
                                  value={formData.sV32}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}

                        {row.id === "four" && (
                          <>
                            <Box sx={{ marginLeft: 3 }}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV4"
                                  value={formData.sV4}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold'}}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3,}}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV42"
                                  value={formData.sV42}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio/>}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}

                        {row.id === "five" && (
                          <>
                            <Box sx={{ marginLeft: 3, }}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV5"
                                  value={formData.sV5}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3, }}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV52"
                                  value={formData.sV52}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio/>}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box
          sx={{ paddingLeft: "5px", paddingBottom: "2px", paddingTop: "4px" }}
        ></Box>

        <Typography variant="h6" gutterBottom>
          下肢
        </Typography>
        <Box>
          <Table aria-label="simple table">
            <TableBody>
              {[
                {
                  id: "six",
                  label: "Thomas test",
                  description: "・股関節屈曲拘縮",
                },
                {
                  id: "seven",
                  label: "Ely test",
                  description: "・大腿直筋の短縮",
                },
                {
                  id: "eight",
                  label: "Over test",
                  description: "・腸脛靭帯の短縮",
                },
                {
                  id: "nine",
                  label: "前方引き出しテスト",
                  description: "・前十字靭帯の損傷",
                },
                {
                  id: "ten",
                  label: "後方引き出しテスト",
                  description: "・後十字靭帯の損傷",
                },
                {
                  id: "eleven",
                  label: "外反ストレステスト",
                  description: "・膝内側側副靭帯の異常",
                },
                {
                  id: "twelve",
                  label: "内反ストレステスト",
                  description: "・膝外側側副靭帯の異常",
                },
                {
                  id: "thirteen",
                  label: "McMurry test",
                  description: "・半月板損傷",
                },
                {
                  id: "fourteen",
                  label: "Apley test",
                  description: "・半月板損傷",
                },
                {
                  id: "fifteen",
                  label: "Thompson test",
                  description: "・アキレス腱損傷",
                },
              ].map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      backgroundColor: "#5972ff",
                      width: "36%",
                      color: "white",
                    }}
                  >
                    {row.label}
                    <div style={{ paddingTop: "10px" }}>{row.description}</div>
                  </TableCell>

                  <TableCell align="left">
                    <Box>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                      >
                        {row.id === "six" && (
                          <>
                            <Box sx={{ marginLeft: 3, }}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV6"
                                  value={formData.sV6}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3,}}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV62"
                                  value={formData.sV62}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}

                        {row.id === "seven" && (
                          <>
                            <Box sx={{ marginLeft: 3, }}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV7"
                                  value={formData.sV7}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3, }}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV72"
                                  value={formData.sV72}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}

                        {row.id === "eight" && (
                          <>
                            <Box sx={{ marginLeft: 3 }}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV8"
                                  value={formData.sV8}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3}}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV82"
                                  value={formData.sV82}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}

                        {row.id === "nine" && (
                          <>
                            <Box sx={{ marginLeft: 3 }}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV9"
                                  value={formData.sV9}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3}}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV92"
                                  value={formData.sV92}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}

                        {row.id === "ten" && (
                          <>
                            <Box sx={{ marginLeft: 3,}}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV10"
                                  value={formData.sV10}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3 }}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV102"
                                  value={formData.sV102}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}
                        {row.id === "eleven" && (
                          <>
                            <Box sx={{ marginLeft: 3 }}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV11"
                                  value={formData.sV11}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3}}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV112"
                                  value={formData.sV112}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}
                        {row.id === "twelve" && (
                          <>
                            <Box sx={{ marginLeft: 3}}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV1212"
                                  value={formData.sV1212}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3,}}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV12122"
                                  value={formData.sV12122}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}
                        {row.id === "thirteen" && (
                          <>
                            <Box sx={{ marginLeft: 3}}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV1313"
                                  value={formData.sV1313}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3}}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV13132"
                                  value={formData.sV13132}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}
                        {row.id === "fourteen" && (
                          <>
                            <Box sx={{ marginLeft: 3}}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV1414"
                                  value={formData.sV1414}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3, }}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV14142"
                                  value={formData.sV14142}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}
                        {row.id === "fifteen" && (
                          <>
                            <Box sx={{ marginLeft: 3, }}>
                              <FormControl>
                                <FormLabel id="existence">右</FormLabel>
                                <RadioGroup
                                  name="sV1515"
                                  value={formData.sV1515}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{ color: "red" }} />}
                                    label={<span style={{ color: 'red' }}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: 3 }}>
                              <FormControl>
                                <FormLabel id="existence">左</FormLabel>
                                <RadioGroup
                                  name="sV15152"
                                  value={formData.sV15152}
                                  onChange={handleChange}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap:"10px"
                                  }}
                                >
                                  <FormControlLabel
                                    value="(+)"
                                    control={<Radio />}
                                    label={<span style={{ fontWeight: 'bold' }}>陽性 +</span>}
                                  />
                                  <FormControlLabel
                                    value="(-)"
                                    control={<Radio sx={{color:"red"}}/>}
                                    label={<span style={{color:"red"}}>陰性 -</span>}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Button
            variant="contained"
            color="success"
            sx={{
              position: "fixed",
              right: [10, 50, 100],
              top: [150, 150, 100],
              size: ["small", "medium", "large"],
              zIndex: 12,
            }}
            onClick={handleSaveDraft}
          >
            一時保存
          </Button>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
        {pdfFilePath && (
          <PdfModal
            open={isPdfModalOpen}
            onClose={() => setIsPdfModalOpen(false)}
            pdfFilePath={pdfFilePath}
          />
        )}
        <FixedBottomNavigation
          editPDF={editPDF}
          handleRefresh={handleRefresh}
        />
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // この行を追加
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
