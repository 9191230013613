import React, { useEffect, useState } from 'react';
import { Modal, Box, Paper, Typography, Button, MobileStepper } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


function TutorialModal({ images,tutorialId }) {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;
  const [open, setOpen] = useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
  };



  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  useEffect (()=>{
    const hasSeenTutorial = localStorage.getItem(`hasSeenTutorial_${tutorialId}`);
    if(!hasSeenTutorial){
      setOpen(true);
    }else{
      setOpen(false);
    }
  },[tutorialId]);

  const handleClose = ()=>{
    localStorage.setItem(`hasSeenTutorial_${tutorialId}`,"true")
    setOpen(false);
  }





return (
  <Modal open={open} onClose={handleClose}>
  <Box sx={modalStyle}>
    <Paper square elevation={0} sx={{  width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50, bgcolor: 'background.default' }}>
      <Typography>{images[activeStep].label}</Typography>
    </Paper>
    <Box
      component="img"
      sx={{ height:"100%",width: '100%',objectFit: 'cover' }}
      src={images[activeStep].imgPath}
      alt={images[activeStep].label}
    />
    <MobileStepper
      variant="dots"
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      sx={{ width: '100%', }}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
          Next
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft />
          Back
        </Button>
      }
    />
  </Box>
</Modal>
);
}

export default TutorialModal;