import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import Snackbar from "@mui/material/Snackbar";

const defaultTheme = createTheme();

function SmallTextField(props) {
  return <TextField size="small" {...props} />;
}

export default function Register() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const navigate = useNavigate();

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
        return value ? "" : "名を入力してください。";
      case "lastName":
        return value ? "" : "性を入力してください。";
      case "email":
        return /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)
          ? ""
          : "有効なメールアドレスを入力してください。";
      case "password":
        return value.length >= 6 && /^[a-zA-Z0-9]+$/.test(value)
          ? ""
          : "パスワードは6文字以上の英数字である必要があります。";
      case "confirmPassword":
        return value === password ? "" : "パスワードが一致しません。";
      default:
        return "";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrors({ ...errors, [name]: validateField(name, value) });

    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {
      firstName: validateField("firstName", firstName),
      lastName: validateField("lastName", lastName),
      email: validateField("email", email),
      password: validateField("password", password),
      confirmPassword: validateField("confirmPassword", confirmPassword),
    };
    setErrors(newErrors);

    // エラーがあるかチェック
    const hasErrors = Object.values(newErrors).some((error) => error);
    if (hasErrors) return;

    const data = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/signup`, data);
      
      if (response.data.status === "success") {
        setSuccessMessage(
          "アカウント登録が完了しました。ログインページへ移動します。"
        );
        setRedirectToLogin(true);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("登録に失敗しました。");
    }
  };

  useEffect(() => {
    if (redirectToLogin) {
      setTimeout(() => {
        navigate("/login");
      }, 3000); // 3秒後にリダイレクト
    }
  }, [redirectToLogin, navigate]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3} sx={{ mt: 3, padding: 3, borderRadius: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                bgcolor: "#0cc0df",
                width: 45,
                height: 45,
                boxShadow: 3,
              }}
            >
              <LockOutlinedIcon sx={{ fontSize: 32, color: "white" }} />
            </Avatar>
            <Typography component="h1" variant="h6">
              アカウント作成
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <Grid container spacing={2}>
                
                <Grid item xs={12} sm={6}>
                  <SmallTextField
                    required
                    fullWidth
                    id="lastName"
                    label="性"
                    name="lastName"
                    autoComplete="family-name"
                    value={lastName}
                    onChange={handleChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SmallTextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="名"
                    autoFocus
                    value={firstName}
                    onChange={handleChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SmallTextField
                    required
                    fullWidth
                    id="email"
                    label="メールアドレス"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SmallTextField
                    required
                    fullWidth
                    name="password"
                    label="パスワード"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={handleChange}
                    error={!!errors.password}
                    helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SmallTextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="パスワードの確認"
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                  />
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                )}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontFamily: "'Noto Sans JP', sans-serif !important",
                  backgroundColor: "#0cc0df",

                  fontWeight: "bold !important",
                  "&:hover": {
                    backgroundColor: "#0097b2 !important",
                  },
                }}
              >
                アカウント登録
              </Button>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="body2">
                    既にアカウントをお持ちですか？
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to= "/Login" style={{fontSize:"16px",textDecoration:"none"}}>
                    ログイン画面
                  </Link>
                </Grid>
              </Grid>
            </Box>
            {successMessage && (
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={() => setOpen(false)}
                  severity="success"
                  variant="filled"
                >
                  {successMessage}
                </Alert>
              </Snackbar>
            )}
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
